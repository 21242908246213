<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(184,32)" d="m0 0h1231l26 3 19 5 19 8 16 9 13 10 14 12 9 10 10 13 9 16 8 17 6 20 3 17 1 16 1 140v343h34l4-2v2h110l3-2v3l113-1 30 1 21 2 24 6 15 6 17 9 14 10 14 12 11 12 10 13 11 19 8 20 5 19 2 12 1 14v845l-2 19-4 18-5 15-10 21-7 11-10 13-11 12-9 8-17 12-18 10-20 7-16 4-14 2-15 1h-290v39l-2 20-5 21-6 16-11 21-11 15-9 10-4 5-11 9-10 8-19 11-16 7-20 6-18 3-15 1h-1223l-23-2-21-5-16-6-21-11-14-10-11-10-2-1v-2l-4-2-7-8-11-15-10-17-9-22-5-20-2-17v-1689l3-20 5-19 9-21 10-17 10-13 12-13 10-9 14-10 16-9 16-7 17-5 18-3zm-3 64-16 3-15 6-10 6-12 9-9 9-10 14-7 14-4 13-2 12v1684l3 16 6 16 8 13 8 10 8 8 13 9 16 8 14 4 14 2h137 3 110l3-1 1 1h109l3-1 1 1h108l3-1 1 1h221l3-1 1 1h110l3-1 1 1h109l3-1 1 1h295l21-3 16-6 14-8 13-11 9-10 8-13 6-14 4-17 1-9v-37l-291-1-24-2-21-5-21-8-18-10-12-9-14-12-11-12-11-16-10-18-6-15-6-25-2-15-1-64v-732l1-62 3-20 6-21 8-18 8-14 10-14 14-15 11-10 10-7 17-10 17-7 21-6 23-3 59-1 3-1 2 1h222l12-1 1-16v-467l-2-16-4-14-8-16-10-14-9-9-14-10-14-7-13-4-13-2zm1026 640-17 3-14 5-11 6-12 9-11 11-7 10-9 17-4 13-2 13v851l4 19 5 13 8 13 8 10 8 8 14 10 15 7 14 4 14 2h323 2 325l20-3 15-5 16-9 13-11 9-10 9-15 4-9 4-15 2-15v-839l-2-18-5-16-8-15-10-13-10-10-15-10-14-6-15-4-10-1z" />
    <path transform="translate(491,704)" d="m0 0h26l10 3 10 9 4 5 3 12v259h261l10 3 10 8 6 12 1 5v18l-2 25-4 24-6 25-6 19-8 20-13 27-12 20-10 14-10 13-12 14-15 16-8 7-10 9-13 10-16 11-20 12-21 11-27 11-23 7-28 6-29 4-16 1h-23l-27-2-28-5-21-5-30-10-24-11-19-10-19-12-19-14-14-12-17-16-18-20-15-20-9-14-10-17-8-16-10-23-9-27-6-27-4-27-1-11v-41l3-28 6-31 7-23 10-26 8-16 10-19 9-14 7-10 12-16 11-12 7-8 16-16 28-22 18-12 21-12 21-10 24-9 27-8 27-5zm-13 65-36 8-21 7-21 9-20 11-11 7-19 14-16 15-14 14-9 11-12 16-9 15-12 23-8 20-6 19-5 23-2 15-1 13v32l3 26 6 27 8 23 8 18 11 21 10 15 10 13 12 14 17 17 11 9 13 10 16 10 12 7 19 9 18 7 14 4 21 5 22 3 13 1h25l20-2 27-5 24-7 20-8 16-8 16-9 16-11 10-8 10-9 8-7 9-9 18-22 14-21 12-23 9-23 8-27 4-21v-4l-257-1-9-2-9-6-6-7-4-10-1-18v-242z" />
    <path transform="translate(1366,798)" d="m0 0 1 2 107-1 5-1v2l114-1h226l41 1 9 2 10 7 6 9 2 5 1 6v262l-2 9-6 9-8 7-7 3-8 1h-642l-11-2-9-6-7-8-4-9-1-17v-96l-1-2 1-4v-112l1-38 4-11 6-7 9-6 8-2 151-1zm-117 65-1 1-1 191 1 1h85l2-2 1 2h109l5-4-1 4h108l5-3v3h261l1-1v-191z" />
    <path transform="translate(601,608)" d="m0 0h24l32 3 24 5 23 6 25 9 24 11 15 8 19 12 12 9 14 11 11 10 8 7 12 12 9 11 10 12 14 20 12 20 8 16 8 18 9 25 7 27 5 30 2 25v18l-4 11-9 10-10 5-9 1h-290l-10-2-8-5-7-7-4-9-1-4-1-21v-114l1-165 4-10 8-9 10-5zm39 65-1 222 1 1h222l-1-9-6-28-9-26-9-20-9-17-10-15-13-17-11-12-17-17-11-9-18-13-19-11-25-12-30-10-32-7z" />
    <path transform="translate(1504,1248)" d="m0 0h63l1 1v191l61-1h112l83 1v63l-2 1h-254v191l-1 1h-62l-1-1-1-22v-169h-254l-1-1-1-28 1-35 154-1h101v-160z" />
    <path transform="translate(924,223)" d="m0 0 67 1 1 1v62l-1 1h-127v31l39 1 15 2 18 6 14 8 11 9 10 10 10 16 7 16 3 14 1 7v16l-3 17-6 16-6 11-10 13-8 8-13 9-16 8-19 5-10 1h-11l-15-2-13-4-16-8-9-6-10-9-8-9-8-13-5-10-5-19-1-7v-9h63l2 7 6 12 7 7 9 4 5 1 13-1 10-5 8-9 3-7 1-5v-9l-4-11-6-8-8-5-7-2-74-1-10-3-8-6-6-7-4-11v-107l4-11 7-8 10-6 4-1z" />
    <path transform="translate(661,224)" d="m0 0h21l17 3 15 6 13 8 10 8 10 10 10 16 6 14 4 14 1 8v13l-3 10-9 13-11 13-7 9-11 14-14 17-10 13-14 17-11 14-3 4h92l1 1v62l-1 1h-161l-10-2-8-5-7-7-5-12v-12l4-11 13-16 13-17 14-17 11-14 14-17 13-16 7-9 8-10 11-14 13-16 5-8-6-9-5-5-6-4-7-2h-13l-10 4-7 6-7 14-2 7h-62l-1-1v-9l3-16 6-16 7-12 8-10 11-11 15-10 16-7 13-3z" />
    <path transform="translate(1685,1194)" d="m0 0 4 1 54 54 4-2 53-53 4 1 6 7 7 6 5 6 6 5 5 6 7 6v2l4 2 2 4-1 2h-2v2l-21 21h-2l-2 4-20 20h-2l-2 4-4 4 55 55 1 4-10 9-31 31-2 3-5-2-51-51-1-2-5 1-5 5-9 10-21 21-8 7-11 11h-2l-7-8-33-33-3-2 1-4 54-54-2-4-53-53 1-4z" />
    <path transform="translate(512,1440)" d="m0 0h63l1 1v381l-5 2h-59l-1-50v-259z" />
    <path transform="translate(829,1503)" d="m0 0 3 2 1 231-1 87-7 1h-57l-1-49v-33l1-5-3-1 2-4v-226l1-2z" f/>
    <path transform="translate(441,1503)" d="m0 0h5l2 2v318l-1 1h-63l-1-28v-286l1-6z" />
    <path transform="translate(1281,1216)" d="m0 0h62l1 1v59l-1 4h64l1 1v62l-1 1h-63v63l-1 1h-62l-1-1-1-12v-51h-62l-1-1-1-59 2-4 62-1v-59z" />
    <path transform="translate(1251,210)" d="m0 0 6 2 19 9 19 10 13 7-1 5-16 32-9 17-11 23-82 164-8 15-6-2-46-23-5-3 1-5 120-240z" />
    <path transform="translate(641,1568)" d="m0 0h63l1 4v36l-1 215-1 1h-62l-1-1-1-42v-146l1-66z" f/>
    <path transform="translate(256,1600)" d="m0 0h64v223l-1 1h-63l-1-23v-192z" />
    <path transform="translate(1273,1566)" d="m0 0 1 2 115-1 18 1 1 1v63h-192l-1-1 1-62 1-1 54-1z" f/>
    <path transform="translate(1773,1630)" d="m0 0 2 1 80 1 1 1v62l-3 1h-189l-1-31v-32l1-1 107-1z" f/>
    <path transform="translate(1758,1535)" d="m0 0 98 1v63l-3 1h-189v-63l1-1z" />
    <path transform="translate(1341,383)" d="m0 0 3 2 1 61-3 2h-62v-64z" />
    <path transform="translate(1088,256)" d="m0 0h63l1 1v62l-1 1h-63z" />
    <path transform="translate(583,1712)" d="m0 0 4 2 24 24h-3l-25-25z" />
    <path transform="translate(500,1662)" d="m0 0v3l-15 16-7 7v-3l12-12 7-8z" />
    <path transform="translate(711,1727)" d="m0 0 4 2 20 20h-3l-21-21z" />
    <path transform="translate(738,1651)" d="m0 0v3l-19 19-1 2-2-1 7-8z" />
    <path transform="translate(733,1769)" d="m0 0v3l-14 14v-3z" />
    <path transform="translate(607,1782)" d="m0 0 2 1-11 11v-3z" />
    <path transform="translate(470,1712)" d="m0 0 5 3 7 7h-3l-7-7-2-1z" />
    <path transform="translate(709,1611)" d="m0 0 7 6 4 5-4-2-7-7z" />
    <path transform="translate(720,1622)" d="m0 0 7 6 3 4-4-2-6-6z" />
    <path transform="translate(104,556)" d="m0 0 4 2 6 7-4-2z" />
    <path transform="translate(1046,1455)" d="m0 0 2 1-6 6-2-1z" />
    <path transform="translate(1726,662)" d="m0 0 2 1-5 5-2-1z" />
    <path transform="translate(717,1786)" d="m0 0v3h-3z" />
    <path transform="translate(486,1727)" d="m0 0 4 2-1 2-3-2z" />
    <path transform="translate(482,1723)" d="m0 0 4 2-1 2-3-2z" />
    <path transform="translate(755,1633)" d="m0 0 2 1-4 3z" />
    <path transform="translate(713,1790)" d="m0 0v3l-2 1z" />
    <path transform="translate(848,1637)" d="m0 0 4 2h-3z" />
    <path transform="translate(1e3 1946)" d="m0 0 2 1-3 2z" />
    <path transform="translate(554,1946)" d="m0 0 2 1-3 2z" />
    <path transform="translate(611,1739)" d="m0 0 3 3-3-1z" />
    <path transform="translate(762,1739)" d="m0 0v3l-2-1z" />
    <path transform="translate(750,1638)" d="m0 0 2 1-3 2z" />
    <path transform="translate(842,1631)" d="m0 0 3 3-3-1z" />
    <path transform="translate(1052,1223)" d="m0 0v3l-2-1z" />
    <path transform="translate(1457,1044)" d="m0 0v3l-2-1z" />
    <path transform="translate(1595,793)" d="m0 0 2 1-3 2z" />
    <path transform="translate(1625,764)" d="m0 0v3l-2-1z" />
    <path transform="translate(1382,667)" d="m0 0v3l-2-1z" />
    <path transform="translate(678,1826)" d="m0 0 2 1h-3z" />
    <path transform="translate(751,1768)" d="m0 0 3 1h-3z" />
    <path transform="translate(714,1676)" d="m0 0v3z" />
    <path transform="translate(747,1641)" d="m0 0 2 1h-3z" />
    <path transform="translate(838,1628)" d="m0 0 3 1h-3z" />
    <path transform="translate(1682,666)" d="m0 0 3 1h-3z" />
    <path transform="translate(1572,330)" d="m0 0 2 1-1 2z" />
    <path transform="translate(609,1780)" d="m0 0 2 1-2 1z" />
    <path transform="translate(491,1732)" d="m0 0 2 2h-2z" />
    <path transform="translate(730,1632)" d="m0 0 2 2h-2z" />
    <path transform="translate(107,1348)" d="m0 0 2 2h-2z" />
    <path transform="translate(1042,1346)" d="m0 0 2 1-2 1z" />
    <path transform="translate(1452,1049)" d="m0 0 1 2-2-1z" />
    <path transform="translate(1339,1049)" d="m0 0 1 2-2-1z" />
    <path transform="translate(106,444)" d="m0 0 2 2h-2z" />
    <path transform="translate(593,1796)" d="m0 0 2 1z" />
    <path transform="translate(763,1780)" d="m0 0" />
    <path transform="translate(506,1769)" d="m0 0 2 1z" />
    <path transform="translate(739,1763)" d="m0 0 2 1z" />
    <path transform="translate(504,1746)" d="m0 0" />
    <path transform="translate(466,1709)" d="m0 0 2 1z" />
    <path transform="translate(597,1680)" d="m0 0" />
    <path transform="translate(601,1676)" d="m0 0" />
    <path transform="translate(739,1641)" d="m0 0" />
    <path transform="translate(837,1626)" d="m0 0" />
    <path transform="translate(103,1345)" d="m0 0 2 1z" />
    <path transform="translate(1052,1279)" d="m0 0" />
    <path transform="translate(1621,768)" d="m0 0" />
    <path transform="translate(1584,455)" d="m0 0 2 1z" />
    <path transform="translate(1572,443)" d="m0 0" />
    <path transform="translate(1494,443)" d="m0 0 2 1z" />
    <path transform="translate(1582,340)" d="m0 0 2 1z" />
    <path transform="translate(1229,1949)" d="m0 0" />
    <path transform="translate(1e3 1949)" d="m0 0" />
    <path transform="translate(552,1949)" d="m0 0" />
    <path transform="translate(439,1949)" d="m0 0" />
    <path transform="translate(326,1949)" d="m0 0" />
    <path transform="translate(891,1948)" d="m0 0" />
    <path transform="translate(440,1948)" d="m0 0" />
    <path transform="translate(933,1947)" d="m0 0" />
    <path transform="translate(665,1947)" d="m0 0" />
    <path transform="translate(441,1947)" d="m0 0" />
    <path transform="translate(1119,1946)" d="m0 0" />
    <path transform="translate(932,1946)" d="m0 0" />
    <path transform="translate(893,1946)" d="m0 0" />
    <path transform="translate(442,1946)" d="m0 0" />
    <path transform="translate(1007,1945)" d="m0 0" />
    <path transform="translate(556,1945)" d="m0 0" />
    <path transform="translate(443,1945)" d="m0 0" />
    <path transform="translate(557,1944)" d="m0 0" />
    <path transform="translate(558,1943)" d="m0 0" />
    <path transform="translate(680,1825)" d="m0 0" />
    <path transform="translate(579,1812)" d="m0 0" />
    <path transform="translate(580,1811)" d="m0 0" />
    <path transform="translate(708,1796)" d="m0 0" />
    <path transform="translate(709,1795)" d="m0 0" />
    <path transform="translate(595,1795)" d="m0 0" />
    <path transform="translate(710,1794)" d="m0 0" />
    <path transform="translate(596,1794)" d="m0 0" />
    <path transform="translate(764,1782)" d="m0 0" />
    <path transform="translate(762,1779)" d="m0 0" />
    <path transform="translate(611,1779)" d="m0 0" />
    <path transform="translate(761,1778)" d="m0 0" />
    <path transform="translate(760,1777)" d="m0 0" />
    <path transform="translate(759,1776)" d="m0 0" />
    <path transform="translate(754,1770)" d="m0 0" />
    <path transform="translate(734,1768)" d="m0 0" />
    <path transform="translate(508,1768)" d="m0 0" />
    <path transform="translate(735,1767)" d="m0 0" />
    <path transform="translate(736,1766)" d="m0 0" />
    <path transform="translate(737,1765)" d="m0 0" />
    <path transform="translate(738,1764)" d="m0 0" />
    <path transform="translate(741,1762)" d="m0 0" />
    <path transform="translate(746,1755)" d="m0 0" />
    <path transform="translate(747,1754)" d="m0 0" />
    <path transform="translate(739,1754)" d="m0 0" />
    <path transform="translate(748,1753)" d="m0 0" />
    <path transform="translate(738,1753)" d="m0 0" />
    <path transform="translate(749,1752)" d="m0 0" />
    <path transform="translate(737,1752)" d="m0 0" />
    <path transform="translate(750,1751)" d="m0 0" />
    <path transform="translate(736,1751)" d="m0 0" />
    <path transform="translate(751,1750)" d="m0 0" />
    <path transform="translate(735,1750)" d="m0 0" />
    <path transform="translate(507,1750)" d="m0 0" />
    <path transform="translate(752,1749)" d="m0 0" />
    <path transform="translate(506,1749)" d="m0 0" />
    <path transform="translate(753,1748)" d="m0 0" />
    <path transform="translate(505,1748)" d="m0 0" />
    <path transform="translate(754,1747)" d="m0 0" />
    <path transform="translate(844,1746)" d="m0 0" />
    <path transform="translate(755,1746)" d="m0 0" />
    <path transform="translate(618,1746)" d="m0 0" />
    <path transform="translate(843,1745)" d="m0 0" />
    <path transform="translate(756,1745)" d="m0 0" />
    <path transform="translate(617,1745)" d="m0 0" />
    <path transform="translate(503,1745)" d="m0 0" />
    <path transform="translate(842,1744)" d="m0 0" />
    <path transform="translate(757,1744)" d="m0 0" />
    <path transform="translate(616,1744)" d="m0 0" />
    <path transform="translate(758,1743)" d="m0 0" />
    <path transform="translate(615,1743)" d="m0 0" />
    <path transform="translate(759,1742)" d="m0 0" />
    <path transform="translate(614,1742)" d="m0 0" />
    <path transform="translate(500,1741)" d="m0 0" />
    <path transform="translate(837,1740)" d="m0 0" />
    <path transform="translate(499,1740)" d="m0 0" />
    <path transform="translate(836,1739)" d="m0 0" />
    <path transform="translate(498,1739)" d="m0 0" />
    <path transform="translate(763,1738)" d="m0 0" />
    <path transform="translate(497,1738)" d="m0 0" />
    <path transform="translate(764,1737)" d="m0 0" />
    <path transform="translate(496,1737)" d="m0 0" />
    <path transform="translate(495,1736)" d="m0 0" />
    <path transform="translate(494,1735)" d="m0 0" />
    <path transform="translate(493,1734)" d="m0 0" />
    <path transform="translate(490,1731)" d="m0 0" />
    <path transform="translate(710,1726)" d="m0 0" />
    <path transform="translate(709,1725)" d="m0 0" />
    <path transform="translate(708,1724)" d="m0 0" />
    <path transform="translate(707,1723)" d="m0 0" />
    <path transform="translate(460,1706)" d="m0 0" />
    <path transform="translate(463,1700)" d="m0 0" />
    <path transform="translate(456,1696)" d="m0 0" />
    <path transform="translate(469,1695)" d="m0 0" />
    <path transform="translate(455,1695)" d="m0 0" />
    <path transform="translate(470,1694)" d="m0 0" />
    <path transform="translate(473,1691)" d="m0 0" />
    <path transform="translate(252,1684)" d="m0 0" />
    <path transform="translate(104,1684)" d="m0 0" />
    <path transform="translate(708,1683)" d="m0 0" />
    <path transform="translate(253,1683)" d="m0 0" />
    <path transform="translate(709,1682)" d="m0 0" />
    <path transform="translate(710,1681)" d="m0 0" />
    <path transform="translate(711,1680)" d="m0 0" />
    <path transform="translate(712,1679)" d="m0 0" />
    <path transform="translate(1499,1678)" d="m0 0" />
    <path transform="translate(598,1678)" d="m0 0" />
    <path transform="translate(1500,1677)" d="m0 0" />
    <path transform="translate(715,1675)" d="m0 0" />
    <path transform="translate(602,1674)" d="m0 0" />
    <path transform="translate(605,1673)" d="m0 0" />
    <path transform="translate(764,1669)" d="m0 0" />
    <path transform="translate(836,1668)" d="m0 0" />
    <path transform="translate(763,1668)" d="m0 0" />
    <path transform="translate(762,1666)" d="m0 0" />
    <path transform="translate(761,1665)" d="m0 0" />
    <path transform="translate(839,1664)" d="m0 0" />
    <path transform="translate(760,1664)" d="m0 0" />
    <path transform="translate(759,1663)" d="m0 0" />
    <path transform="translate(841,1662)" d="m0 0" />
    <path transform="translate(501,1661)" d="m0 0" />
    <path transform="translate(502,1660)" d="m0 0" />
    <path transform="translate(503,1659)" d="m0 0" />
    <path transform="translate(504,1658)" d="m0 0" />
    <path transform="translate(506,1657)" d="m0 0" />
    <path transform="translate(507,1656)" d="m0 0" />
    <path transform="translate(508,1655)" d="m0 0" />
    <path transform="translate(739,1650)" d="m0 0" />
    <path transform="translate(740,1649)" d="m0 0" />
    <path transform="translate(745,1643)" d="m0 0" />
    <path transform="translate(736,1638)" d="m0 0" />
    <path transform="translate(752,1637)" d="m0 0" />
    <path transform="translate(735,1637)" d="m0 0" />
    <path transform="translate(507,1637)" d="m0 0" />
    <path transform="translate(847,1636)" d="m0 0" />
    <path transform="translate(734,1636)" d="m0 0" />
    <path transform="translate(506,1636)" d="m0 0" />
    <path transform="translate(846,1635)" d="m0 0" />
    <path transform="translate(733,1635)" d="m0 0" />
    <path transform="translate(505,1635)" d="m0 0" />
    <path transform="translate(845,1634)" d="m0 0" />
    <path transform="translate(732,1634)" d="m0 0" />
    <path transform="translate(504,1633)" d="m0 0" />
    <path transform="translate(757,1632)" d="m0 0" />
    <path transform="translate(503,1632)" d="m0 0" />
    <path transform="translate(841,1630)" d="m0 0" />
    <path transform="translate(758,1630)" d="m0 0" />
    <path transform="translate(501,1630)" d="m0 0" />
    <path transform="translate(1774,1629)" d="m0 0" />
    <path transform="translate(759,1629)" d="m0 0" />
    <path transform="translate(500,1629)" d="m0 0" />
    <path transform="translate(760,1628)" d="m0 0" />
    <path transform="translate(613,1628)" d="m0 0" />
    <path transform="translate(499,1628)" d="m0 0" />
    <path transform="translate(761,1627)" d="m0 0" />
    <path transform="translate(1741,1626)" d="m0 0" />
    <path transform="translate(762,1626)" d="m0 0" />
    <path transform="translate(498,1626)" d="m0 0" />
    <path transform="translate(836,1625)" d="m0 0" />
    <path transform="translate(763,1625)" d="m0 0" />
    <path transform="translate(497,1625)" d="m0 0" />
    <path transform="translate(835,1624)" d="m0 0" />
    <path transform="translate(764,1624)" d="m0 0" />
    <path transform="translate(609,1624)" d="m0 0" />
    <path transform="translate(496,1624)" d="m0 0" />
    <path transform="translate(834,1623)" d="m0 0" />
    <path transform="translate(765,1623)" d="m0 0" />
    <path transform="translate(495,1623)" d="m0 0" />
    <path transform="translate(607,1622)" d="m0 0" />
    <path transform="translate(494,1622)" d="m0 0" />
    <path transform="translate(606,1621)" d="m0 0" />
    <path transform="translate(493,1621)" d="m0 0" />
    <path transform="translate(605,1620)" d="m0 0" />
    <path transform="translate(492,1620)" d="m0 0" />
    <path transform="translate(491,1619)" d="m0 0" />
    <path transform="translate(603,1618)" d="m0 0" />
    <path transform="translate(490,1618)" d="m0 0" />
    <path transform="translate(602,1617)" d="m0 0" />
    <path transform="translate(489,1617)" d="m0 0" />
    <path transform="translate(601,1616)" d="m0 0" />
    <path transform="translate(488,1616)" d="m0 0" />
    <path transform="translate(487,1615)" d="m0 0" />
    <path transform="translate(599,1614)" d="m0 0" />
    <path transform="translate(486,1614)" d="m0 0" />
    <path transform="translate(1499,1613)" d="m0 0" />
    <path transform="translate(485,1613)" d="m0 0" />
    <path transform="translate(597,1612)" d="m0 0" />
    <path transform="translate(484,1612)" d="m0 0" />
    <path transform="translate(483,1611)" d="m0 0" />
    <path transform="translate(708,1610)" d="m0 0" />
    <path transform="translate(595,1610)" d="m0 0" />
    <path transform="translate(482,1610)" d="m0 0" />
    <path transform="translate(707,1609)" d="m0 0" />
    <path transform="translate(481,1609)" d="m0 0" />
    <path transform="translate(593,1608)" d="m0 0" />
    <path transform="translate(480,1608)" d="m0 0" />
    <path transform="translate(592,1607)" d="m0 0" />
    <path transform="translate(479,1607)" d="m0 0" />
    <path transform="translate(591,1606)" d="m0 0" />
    <path transform="translate(478,1606)" d="m0 0" />
    <path transform="translate(477,1605)" d="m0 0" />
    <path transform="translate(589,1604)" d="m0 0" />
    <path transform="translate(1499,1566)" d="m0 0" />
    <path transform="translate(1274,1565)" d="m0 0" />
    <path transform="translate(1389,1563)" d="m0 0" />
    <path transform="translate(1339,1563)" d="m0 0" />
    <path transform="translate(1338,1562)" d="m0 0" />
    <path transform="translate(661,1562)" d="m0 0" />
    <path transform="translate(1337,1561)" d="m0 0" />
    <path transform="translate(1336,1560)" d="m0 0" />
    <path transform="translate(379,1557)" d="m0 0" />
    <path transform="translate(380,1556)" d="m0 0" />
    <path transform="translate(763,1554)" d="m0 0" />
    <path transform="translate(1052,1505)" d="m0 0" />
    <path transform="translate(1051,1504)" d="m0 0" />
    <path transform="translate(99,1502)" d="m0 0" />
    <path transform="translate(100,1501)" d="m0 0" />
    <path transform="translate(101,1500)" d="m0 0" />
    <path transform="translate(1048,1454)" d="m0 0" />
    <path transform="translate(1049,1453)" d="m0 0" />
    <path transform="translate(1050,1452)" d="m0 0" />
    <path transform="translate(1051,1450)" d="m0 0" />
    <path transform="translate(1050,1389)" d="m0 0" />
    <path transform="translate(1049,1388)" d="m0 0" />
    <path transform="translate(103,1384)" d="m0 0" />
    <path transform="translate(104,1383)" d="m0 0" />
    <path transform="translate(105,1382)" d="m0 0" />
    <path transform="translate(106,1381)" d="m0 0" />
    <path transform="translate(107,1380)" d="m0 0" />
    <path transform="translate(113,1354)" d="m0 0" />
    <path transform="translate(112,1353)" d="m0 0" />
    <path transform="translate(111,1352)" d="m0 0" />
    <path transform="translate(110,1351)" d="m0 0" />
    <path transform="translate(1038,1350)" d="m0 0" />
    <path transform="translate(109,1350)" d="m0 0" />
    <path transform="translate(1041,1348)" d="m0 0" />
    <path transform="translate(106,1347)" d="m0 0" />
    <path transform="translate(105,1346)" d="m0 0" />
    <path transform="translate(1044,1345)" d="m0 0" />
    <path transform="translate(1045,1344)" d="m0 0" />
    <path transform="translate(1046,1343)" d="m0 0" />
    <path transform="translate(99,1340)" d="m0 0" />
    <path transform="translate(1050,1338)" d="m0 0" />
    <path transform="translate(1051,1337)" d="m0 0" />
    <path transform="translate(1052,1336)" d="m0 0" />
    <path transform="translate(1051,1278)" d="m0 0" />
    <path transform="translate(1050,1277)" d="m0 0" />
    <path transform="translate(1049,1276)" d="m0 0" />
    <path transform="translate(1047,1228)" d="m0 0" />
    <path transform="translate(1048,1227)" d="m0 0" />
    <path transform="translate(1049,1226)" d="m0 0" />
    <path transform="translate(1051,1165)" d="m0 0" />
    <path transform="translate(1505,1052)" d="m0 0" />
    <path transform="translate(1336,1052)" d="m0 0" />
    <path transform="translate(1617,1051)" d="m0 0" />
    <path transform="translate(1563,1051)" d="m0 0" />
    <path transform="translate(1504,1051)" d="m0 0" />
    <path transform="translate(1337,1051)" d="m0 0" />
    <path transform="translate(1616,1050)" d="m0 0" />
    <path transform="translate(1564,1050)" d="m0 0" />
    <path transform="translate(1503,1050)" d="m0 0" />
    <path transform="translate(1390,1050)" d="m0 0" />
    <path transform="translate(1502,1049)" d="m0 0" />
    <path transform="translate(1389,1049)" d="m0 0" />
    <path transform="translate(1501,1048)" d="m0 0" />
    <path transform="translate(1453,1048)" d="m0 0" />
    <path transform="translate(1388,1048)" d="m0 0" />
    <path transform="translate(1340,1048)" d="m0 0" />
    <path transform="translate(1500,1047)" d="m0 0" />
    <path transform="translate(1454,1047)" d="m0 0" />
    <path transform="translate(1341,1047)" d="m0 0" />
    <path transform="translate(1499,1046)" d="m0 0" />
    <path transform="translate(1458,1043)" d="m0 0" />
    <path transform="translate(1345,1043)" d="m0 0" />
    <path transform="translate(1459,1042)" d="m0 0" />
    <path transform="translate(1460,1041)" d="m0 0" />
    <path transform="translate(1461,1040)" d="m0 0" />
    <path transform="translate(1462,1039)" d="m0 0" />
    <path transform="translate(1463,1038)" d="m0 0" />
    <path transform="translate(1915,1012)" d="m0 0" />
    <path transform="translate(1913,1010)" d="m0 0" />
    <path transform="translate(1912,1009)" d="m0 0" />
    <path transform="translate(1911,1008)" d="m0 0" />
    <path transform="translate(1946,1006)" d="m0 0" />
    <path transform="translate(1909,1006)" d="m0 0" />
    <path transform="translate(1947 1e3)" d="m0 0" />
    <path transform="translate(1948 1e3)" d="m0 0" />
    <path transform="translate(1907 1e3)" d="m0 0" />
    <path transform="translate(1949 1e3)" d="m0 0" />
    <path transform="translate(1903 1e3)" d="m0 0" />
    <path transform="translate(1901,998)" d="m0 0" />
    <path transform="translate(1900,997)" d="m0 0" />
    <path transform="translate(1896,993)" d="m0 0" />
    <path transform="translate(1524,867)" d="m0 0" />
    <path transform="translate(1411,867)" d="m0 0" />
    <path transform="translate(1412,866)" d="m0 0" />
    <path transform="translate(571,802)" d="m0 0" />
    <path transform="translate(1707,795)" d="m0 0" />
    <path transform="translate(1708,794)" d="m0 0" />
    <path transform="translate(1709,793)" d="m0 0" />
    <path transform="translate(1597,792)" d="m0 0" />
    <path transform="translate(1598,791)" d="m0 0" />
    <path transform="translate(1599,790)" d="m0 0" />
    <path transform="translate(1601,788)" d="m0 0" />
    <path transform="translate(1602,787)" d="m0 0" />
    <path transform="translate(1603,786)" d="m0 0" />
    <path transform="translate(1605,784)" d="m0 0" />
    <path transform="translate(1606,783)" d="m0 0" />
    <path transform="translate(1607,782)" d="m0 0" />
    <path transform="translate(1609,780)" d="m0 0" />
    <path transform="translate(1610,779)" d="m0 0" />
    <path transform="translate(1611,778)" d="m0 0" />
    <path transform="translate(1617,772)" d="m0 0" />
    <path transform="translate(1619,770)" d="m0 0" />
    <path transform="translate(1629,760)" d="m0 0" />
    <path transform="translate(1267,669)" d="m0 0" />
    <path transform="translate(1720,668)" d="m0 0" />
    <path transform="translate(1607,668)" d="m0 0" />
    <path transform="translate(1797,667)" d="m0 0" />
    <path transform="translate(1608,667)" d="m0 0" />
    <path transform="translate(1609,666)" d="m0 0" />
    <path transform="translate(1383,666)" d="m0 0" />
    <path transform="translate(1681,665)" d="m0 0" />
    <path transform="translate(1610,665)" d="m0 0" />
    <path transform="translate(1680,664)" d="m0 0" />
    <path transform="translate(1728,661)" d="m0 0" />
    <path transform="translate(1729,660)" d="m0 0" />
    <path transform="translate(1676,660)" d="m0 0" />
    <path transform="translate(1730,659)" d="m0 0" />
    <path transform="translate(120,571)" d="m0 0" />
    <path transform="translate(119,570)" d="m0 0" />
    <path transform="translate(118,569)" d="m0 0" />
    <path transform="translate(117,568)" d="m0 0" />
    <path transform="translate(1584,567)" d="m0 0" />
    <path transform="translate(116,567)" d="m0 0" />
    <path transform="translate(1583,566)" d="m0 0" />
    <path transform="translate(115,566)" d="m0 0" />
    <path transform="translate(1582,565)" d="m0 0" />
    <path transform="translate(114,565)" d="m0 0" />
    <path transform="translate(1577,561)" d="m0 0" />
    <path transform="translate(1576,560)" d="m0 0" />
    <path transform="translate(1572,556)" d="m0 0" />
    <path transform="translate(1571,555)" d="m0 0" />
    <path transform="translate(103,555)" d="m0 0" />
    <path transform="translate(102,554)" d="m0 0" />
    <path transform="translate(101,553)" d="m0 0" />
    <path transform="translate(100,552)" d="m0 0" />
    <path transform="translate(99,551)" d="m0 0" />
    <path transform="translate(1499,485)" d="m0 0" />
    <path transform="translate(1587,458)" d="m0 0" />
    <path transform="translate(1586,457)" d="m0 0" />
    <path transform="translate(1583,454)" d="m0 0" />
    <path transform="translate(1582,453)" d="m0 0" />
    <path transform="translate(1581,452)" d="m0 0" />
    <path transform="translate(1580,451)" d="m0 0" />
    <path transform="translate(1579,450)" d="m0 0" />
    <path transform="translate(1578,449)" d="m0 0" />
    <path transform="translate(1577,448)" d="m0 0" />
    <path transform="translate(110,448)" d="m0 0" />
    <path transform="translate(1576,447)" d="m0 0" />
    <path transform="translate(109,447)" d="m0 0" />
    <path transform="translate(1491,446)" d="m0 0" />
    <path transform="translate(108,446)" d="m0 0" />
    <path transform="translate(1573,445)" d="m0 0" />
    <path transform="translate(1492,445)" d="m0 0" />
    <path transform="translate(1493,444)" d="m0 0" />
    <path transform="translate(1571,442)" d="m0 0" />
    <path transform="translate(1496,442)" d="m0 0" />
    <path transform="translate(102,441)" d="m0 0" />
    <path transform="translate(101,440)" d="m0 0" />
    <path transform="translate(100,439)" d="m0 0" />
    <path transform="translate(1499,438)" d="m0 0" />
    <path transform="translate(1499,372)" d="m0 0" />
    <path transform="translate(1575,364)" d="m0 0" />
    <path transform="translate(1581,339)" d="m0 0" />
    <path transform="translate(1580,338)" d="m0 0" />
    <path transform="translate(1579,337)" d="m0 0" />
    <path transform="translate(1578,336)" d="m0 0" />
    <path transform="translate(1577,335)" d="m0 0" />
    <path transform="translate(1576,334)" d="m0 0" />
    <path transform="translate(1575,333)" d="m0 0" />
    <path transform="translate(1571,329)" d="m0 0" />
    <path transform="translate(1499,325)" d="m0 0" />
    <path transform="translate(1500,324)" d="m0 0" />
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
