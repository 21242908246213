<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(225,127)" d="m0 0h696l45 1 16 3 15 8 13 12 254 254 9 13 5 13 2 12v1032l-2 14-5 13-7 10-7 8-13 9-14 5-17 2h-767l-17-2-13-5-9-6-12-11-149-149v-2l-4-2-98-98-8-10-7-14-3-13-1-95v-891l1-55 3-12 4-9 5-8 9-10 11-8 14-6 12-2zm457 128-335 1 6 7 120 120 2 1h174l5-3v3h109l4-2v2h293l-2-4-121-121h-2v-2l-2-1-235-1zm-426 92v843l127 127h1v-842l-7-8-120-120zm315 164-59 1v896h355 3 110l3-1 1 1h109l8-7 14-14-4 5-14 14v2h54l1-1v-895z" />
    <path transform="translate(1344,640)" d="m0 0 13 1 13 4 12 7 11 10 9 14 6 18 20 81 4 15 10 4 30 11 21 9 24 12 5-2 22-13 28-17 17-10 18-11 14-7 14-3h10l14 2 11 4 10 6 12 11 89 89 7 10 6 14 2 8v21l-4 15-10 18-9 15-17 28-19 32-3 4 1 5 11 22 13 32 10 28 10 3 40 10 55 14 12 5 11 7 8 8 9 14 4 11 2 13v135l-2 14-4 12-8 11-12 12-12 7-25 7-64 16-21 5-3 1-10 29-10 24-11 24-4 7 1 4 6 11 42 70 8 14 5 16 1 14-3 16-6 14-8 10-6 7-6 5-6 7-6 5-6 7h-2l-2 4-4 4h-2l-2 4h-2l-2 4-13 12-5 5-6 7-4 4h-2l-2 4-12 12-11 9-8 5-14 5-16 2-15-2-13-5-29-17-28-17-25-15-12-7-4 1-32 15-28 11-20 7h-3l-4 17-16 64-8 30-6 12-7 9-8 7-11 7-15 5-7 1h-136l-15-2-13-5-13-9-8-8-8-14-6-19-18-73-5-20v-2l-12-3-32-12-29-13-11-6-5 1-29 17-28 17-32 19-13 6-8 2h-21l-15-4-11-6-11-9-91-91-8-11-5-10-3-11-1-9v-11l4-15 6-11 6-8h148l-2 5-7 11-8 13v5l13 12 3 4 6-1 28-17 50-30 10-5 16-3 17 1 15 5 20 11 16 9 27 13 27 10 19 6 27 7 12 6 12 11 7 8 7 14 17 67 7 28v2l27-1 5-18 16-64 5-16 6-12 10-12 11-8 13-6 30-8 27-9 30-13 25-13 20-12 11-4 14-2 15 1 15 5 16 9 23 14 45 27 4 1 2-4h2l2-4 8-8 2-1-1-6-19-32-17-28-10-17-5-10-3-11-1-14 3-16 4-11 8-13 10-18 8-16 10-23 7-20 7-24 6-21 6-11 10-12 11-8 11-5 26-7 68-17h2v-29l-8-1-77-19-18-6-11-7-12-12-7-12-5-16-8-28-8-23-9-21-9-19-14-24-7-14-4-17 1-16 5-16 8-14 36-60 6-9-1-6-16-16-7 3-32 19-46 28-14 5-4 1h-21l-14-4-19-10-17-10-27-13-26-10-26-8-22-6-12-6-3-3v-190z" />
    <path transform="translate(824,576)" d="m0 0h14l12 2 12 5 9 6 10 9 9 14 5 15 1 7v18l5 1 20 9 20 12 13 10 13 12 11 11 10 13 10 15 8 16 6 14 6 18 4 18 2 20v16l-3 16-7 14-8 10-11 9-12 6-11 3-7 1h-11l-15-3-14-7-10-8-7-8-8-14-4-17-2-20-4-13-7-12-10-11-11-7-11-5-10-2h-17l-14 3-15 8-13 12-8 14-4 12-1 6v17l4 15 6 11 9 11 11 8 10 5 11 3 36 3 22 4 20 6 19 8 16 9 17 12 14 12 11 11 11 14 9 13 8 14 7 15 8 24 4 19 2 20v19l-2 21-5 23-8 22-10 20-10 15-9 12-16 17-14 12-10 7-15 9-15 8-12 5h-2v18l-3 14-4 10-9 13-10 9-16 8-10 3-8 1h-12l-13-3-14-7-11-9-8-10-7-14-3-14-1-18-8-4-21-10-20-13-13-11-8-7-8-8-9-11-8-11-8-13-8-16-6-15-6-21-4-25v-25l4-15 7-13 11-12 13-9 14-5 5-1h16l12 2 12 5 9 6 10 9 9 14 5 15 3 26 5 15 7 11 3 3v2l3 1v2l4 2 11 7 13 5 10 2h12l14-3 16-8 11-9 9-13 5-13 2-12v-11l-3-15-7-14-8-10-8-7-16-8-13-3-30-2-19-3-24-7-21-9-16-9-13-9-11-9-12-11-7-8-10-12-10-15-12-23-7-19-4-14-4-28v-31l3-21 6-23 5-13 10-21 7-11 12-16 11-12 11-11 14-11 15-10 19-10 13-5 1-1 1-17 3-15 5-12 9-11 11-10 10-6 11-3z" />
    <path transform="translate(1345,1033)" d="m0 0 11 3 25 9 21 10 21 13 16 12 11 9 7 6v2l4 2 14 15 11 14 14 21 10 18 8 18 7 20 6 23 3 17 2 25v22l-2 23-5 25-6 21-8 21-8 17-12 20-10 14-9 11-9 10-5 6h-2v2l-8 7-14 12-17 12-19 11-16 8-19 8-22 7-1-1v-135l8-7 8-6 10-9 7-7 11-15 8-15 7-19 2-8 2-16v-17l-3-20-5-17-5-12-9-15-9-11-9-10-14-11-9-7-1-4v-44l1-87z" />
    <path transform="translate(718,91)" d="m0 0 2 1-21 21v-3z" />
    <path transform="translate(86,875)" d="m0 0 7 6 12 12h-3l-16-16z" />
    <path transform="translate(259,597)" d="m0 0 7 6 12 12h-3l-16-16z" />
    <path transform="translate(109,700)" d="m0 0v3l-16 16v-3z" />
    <path transform="translate(263,715)" d="m0 0 4 2 12 12h-3l-8-7z" />
    <path transform="translate(710,371)" d="m0 0 4 2 5 6-4-2-5-5z" />
    <path transform="translate(695,114)" d="m0 0 2 1-7 8v-3z" />
    <path transform="translate(361,812)" d="m0 0 7 6 1 2-4-2-4-4z" />
    <path transform="translate(702,363)" d="m0 0 7 6 1 2-4-2-4-4z" />
    <path transform="translate(269,766)" d="m0 0h2l-2 4-4 3 2-4z" />
    <path transform="translate(373,661)" d="m0 0v3l-4 3 2-4z" />
    <path transform="translate(1119,1382)" d="m0 0 2 1-4 3z" />
    <path transform="translate(1123,1378)" d="m0 0 2 1-4 3z" />
    <path transform="translate(269,654)" d="m0 0v3h-3z" />
    <path transform="translate(109,926)" d="m0 0v3l-2 1z" />
    <path transform="translate(371,822)" d="m0 0 3 2-1 2z" />
    <path transform="translate(376,715)" d="m0 0 3 2-1 2z" />
    <path transform="translate(1126,1375)" d="m0 0 2 1-3 2z" />
    <path transform="translate(380,767)" d="m0 0v3l-2-1z" />
    <path transform="translate(273,763)" d="m0 0 1 2-3 1v-2z" />
    <path transform="translate(272,650)" d="m0 0 2 1-3 2z" />
    <path transform="translate(275,647)" d="m0 0 2 1-3 2z" />
    <path transform="translate(379,832)" d="m0 0 2 1-1 2z" />
    <path transform="translate(376,828)" d="m0 0 2 1-1 2z" />
    <path transform="translate(100,822)" d="m0 0 2 1h-3z" />
    <path transform="translate(370,778)" d="m0 0v3z" />
    <path transform="translate(372,710)" d="m0 0 2 1-1 2z" />
    <path transform="translate(264,659)" d="m0 0v3z" />
    <path transform="translate(105,930)" d="m0 0 2 1-2 1z" />
    <path transform="translate(111,924)" d="m0 0 1 2-2-1z" />
    <path transform="translate(107,896)" d="m0 0 2 2h-2z" />
    <path transform="translate(380,879)" d="m0 0 1 2-2-1z" />
    <path transform="translate(83,872)" d="m0 0 2 2h-2z" />
    <path transform="translate(369,820)" d="m0 0 2 2h-2z" />
    <path transform="translate(104,818)" d="m0 0 1 2-2-1z" />
    <path transform="translate(108,814)" d="m0 0 1 2-2-1z" />
    <path transform="translate(109,812)" d="m0 0 2 1-2 1z" />
    <path transform="translate(111,810)" d="m0 0 2 1-2 1z" />
    <path transform="translate(123,800)" d="m0 0 1 2-2-1z" />
    <path transform="translate(366,782)" d="m0 0 2 1-2 1z" />
    <path transform="translate(113,696)" d="m0 0 1 2-2-1z" />
    <path transform="translate(368,667)" d="m0 0 1 2-2-1z" />
    <path transform="translate(375,659)" d="m0 0 1 2-2-1z" />
    <path transform="translate(378,656)" d="m0 0 1 2-2-1z" />
    <path transform="translate(369,552)" d="m0 0 2 1-2 1z" />
    <path transform="translate(721,88)" d="m0 0 2 1-2 1z" />
    <path transform="translate(120,915)" d="m0 0" />
    <path transform="translate(270,878)" d="m0 0" />
    <path transform="translate(119,803)" d="m0 0 2 1z" />
    <path transform="translate(90,719)" d="m0 0 2 1z" />
    <path transform="translate(379,606)" d="m0 0" />
    <path transform="translate(98,598)" d="m0 0" />
    <path transform="translate(108,588)" d="m0 0 2 1z" />
    <path transform="translate(373,548)" d="m0 0 2 1z" />
    <path transform="translate(719,379)" d="m0 0 2 1z" />
    <path transform="translate(983,1405)" d="m0 0" />
    <path transform="translate(757,1405)" d="m0 0" />
    <path transform="translate(984,1404)" d="m0 0" />
    <path transform="translate(985,1403)" d="m0 0" />
    <path transform="translate(986,1402)" d="m0 0" />
    <path transform="translate(613,1402)" d="m0 0" />
    <path transform="translate(1128,1374)" d="m0 0" />
    <path transform="translate(1139,1362)" d="m0 0" />
    <path transform="translate(379,993)" d="m0 0" />
    <path transform="translate(380,992)" d="m0 0" />
    <path transform="translate(381,991)" d="m0 0" />
    <path transform="translate(380,947)" d="m0 0" />
    <path transform="translate(93,943)" d="m0 0" />
    <path transform="translate(96,940)" d="m0 0" />
    <path transform="translate(97,939)" d="m0 0" />
    <path transform="translate(98,938)" d="m0 0" />
    <path transform="translate(99,937)" d="m0 0" />
    <path transform="translate(100,936)" d="m0 0" />
    <path transform="translate(101,935)" d="m0 0" />
    <path transform="translate(102,934)" d="m0 0" />
    <path transform="translate(103,933)" d="m0 0" />
    <path transform="translate(104,932)" d="m0 0" />
    <path transform="translate(123,913)" d="m0 0" />
    <path transform="translate(119,908)" d="m0 0" />
    <path transform="translate(117,906)" d="m0 0" />
    <path transform="translate(116,905)" d="m0 0" />
    <path transform="translate(115,904)" d="m0 0" />
    <path transform="translate(114,903)" d="m0 0" />
    <path transform="translate(113,902)" d="m0 0" />
    <path transform="translate(112,901)" d="m0 0" />
    <path transform="translate(111,900)" d="m0 0" />
    <path transform="translate(110,899)" d="m0 0" />
    <path transform="translate(109,898)" d="m0 0" />
    <path transform="translate(106,895)" d="m0 0" />
    <path transform="translate(105,894)" d="m0 0" />
    <path transform="translate(260,890)" d="m0 0" />
    <path transform="translate(261,889)" d="m0 0" />
    <path transform="translate(265,884)" d="m0 0" />
    <path transform="translate(266,883)" d="m0 0" />
    <path transform="translate(1283,882)" d="m0 0" />
    <path transform="translate(1284,881)" d="m0 0" />
    <path transform="translate(269,880)" d="m0 0" />
    <path transform="translate(273,876)" d="m0 0" />
    <path transform="translate(85,874)" d="m0 0" />
    <path transform="translate(81,870)" d="m0 0" />
    <path transform="translate(275,838)" d="m0 0" />
    <path transform="translate(274,837)" d="m0 0" />
    <path transform="translate(273,836)" d="m0 0" />
    <path transform="translate(272,835)" d="m0 0" />
    <path transform="translate(271,834)" d="m0 0" />
    <path transform="translate(270,833)" d="m0 0" />
    <path transform="translate(269,832)" d="m0 0" />
    <path transform="translate(1283,831)" d="m0 0" />
    <path transform="translate(266,830)" d="m0 0" />
    <path transform="translate(265,829)" d="m0 0" />
    <path transform="translate(264,828)" d="m0 0" />
    <path transform="translate(375,827)" d="m0 0" />
    <path transform="translate(263,827)" d="m0 0" />
    <path transform="translate(374,826)" d="m0 0" />
    <path transform="translate(262,826)" d="m0 0" />
    <path transform="translate(261,825)" d="m0 0" />
    <path transform="translate(260,824)" d="m0 0" />
    <path transform="translate(98,824)" d="m0 0" />
    <path transform="translate(259,823)" d="m0 0" />
    <path transform="translate(102,820)" d="m0 0" />
    <path transform="translate(105,817)" d="m0 0" />
    <path transform="translate(106,816)" d="m0 0" />
    <path transform="translate(113,809)" d="m0 0" />
    <path transform="translate(114,808)" d="m0 0" />
    <path transform="translate(118,804)" d="m0 0" />
    <path transform="translate(365,784)" d="m0 0" />
    <path transform="translate(368,781)" d="m0 0" />
    <path transform="translate(259,778)" d="m0 0" />
    <path transform="translate(260,777)" d="m0 0" />
    <path transform="translate(261,776)" d="m0 0" />
    <path transform="translate(262,775)" d="m0 0" />
    <path transform="translate(373,774)" d="m0 0" />
    <path transform="translate(263,774)" d="m0 0" />
    <path transform="translate(374,773)" d="m0 0" />
    <path transform="translate(264,773)" d="m0 0" />
    <path transform="translate(375,772)" d="m0 0" />
    <path transform="translate(376,771)" d="m0 0" />
    <path transform="translate(377,770)" d="m0 0" />
    <path transform="translate(381,766)" d="m0 0" />
    <path transform="translate(274,762)" d="m0 0" />
    <path transform="translate(287,738)" d="m0 0" />
    <path transform="translate(286,737)" d="m0 0" />
    <path transform="translate(285,736)" d="m0 0" />
    <path transform="translate(283,734)" d="m0 0" />
    <path transform="translate(282,733)" d="m0 0" />
    <path transform="translate(281,732)" d="m0 0" />
    <path transform="translate(280,731)" d="m0 0" />
    <path transform="translate(279,730)" d="m0 0" />
    <path transform="translate(380,721)" d="m0 0" />
    <path transform="translate(89,720)" d="m0 0" />
    <path transform="translate(379,719)" d="m0 0" />
    <path transform="translate(375,714)" d="m0 0" />
    <path transform="translate(262,714)" d="m0 0" />
    <path transform="translate(374,713)" d="m0 0" />
    <path transform="translate(261,713)" d="m0 0" />
    <path transform="translate(260,712)" d="m0 0" />
    <path transform="translate(259,711)" d="m0 0" />
    <path transform="translate(371,709)" d="m0 0" />
    <path transform="translate(370,708)" d="m0 0" />
    <path transform="translate(369,707)" d="m0 0" />
    <path transform="translate(368,706)" d="m0 0" />
    <path transform="translate(367,705)" d="m0 0" />
    <path transform="translate(366,704)" d="m0 0" />
    <path transform="translate(110,699)" d="m0 0" />
    <path transform="translate(111,698)" d="m0 0" />
    <path transform="translate(114,695)" d="m0 0" />
    <path transform="translate(115,694)" d="m0 0" />
    <path transform="translate(117,693)" d="m0 0" />
    <path transform="translate(118,692)" d="m0 0" />
    <path transform="translate(119,691)" d="m0 0" />
    <path transform="translate(120,690)" d="m0 0" />
    <path transform="translate(121,689)" d="m0 0" />
    <path transform="translate(123,688)" d="m0 0" />
    <path transform="translate(365,671)" d="m0 0" />
    <path transform="translate(260,664)" d="m0 0" />
    <path transform="translate(261,663)" d="m0 0" />
    <path transform="translate(262,662)" d="m0 0" />
    <path transform="translate(376,658)" d="m0 0" />
    <path transform="translate(265,658)" d="m0 0" />
    <path transform="translate(379,655)" d="m0 0" />
    <path transform="translate(380,654)" d="m0 0" />
    <path transform="translate(381,653)" d="m0 0" />
    <path transform="translate(270,653)" d="m0 0" />
    <path transform="translate(277,646)" d="m0 0" />
    <path transform="translate(280,618)" d="m0 0" />
    <path transform="translate(279,617)" d="m0 0" />
    <path transform="translate(278,616)" d="m0 0" />
    <path transform="translate(380,608)" d="m0 0" />
    <path transform="translate(378,605)" d="m0 0" />
    <path transform="translate(377,604)" d="m0 0" />
    <path transform="translate(93,604)" d="m0 0" />
    <path transform="translate(376,603)" d="m0 0" />
    <path transform="translate(94,603)" d="m0 0" />
    <path transform="translate(97,600)" d="m0 0" />
    <path transform="translate(101,596)" d="m0 0" />
    <path transform="translate(102,595)" d="m0 0" />
    <path transform="translate(106,590)" d="m0 0" />
    <path transform="translate(110,586)" d="m0 0" />
    <path transform="translate(105,556)" d="m0 0" />
    <path transform="translate(379,542)" d="m0 0" />
    <path transform="translate(768,379)" d="m0 0" />
    <path transform="translate(831,378)" d="m0 0" />
    <path transform="translate(769,378)" d="m0 0" />
    <path transform="translate(656,378)" d="m0 0" />
    <path transform="translate(657,377)" d="m0 0" />
    <path transform="translate(701,362)" d="m0 0" />
    <path transform="translate(1561,205)" d="m0 0" />
    <path transform="translate(1509,153)" d="m0 0" />
    <path transform="translate(1505,149)" d="m0 0" />
    <path transform="translate(1501,145)" d="m0 0" />
    <path transform="translate(577,121)" d="m0 0" />
    <path transform="translate(578,120)" d="m0 0" />
    <path transform="translate(804,119)" d="m0 0" />
    <path transform="translate(805,118)" d="m0 0" />
    <path transform="translate(569,117)" d="m0 0" />
    <path transform="translate(697,113)" d="m0 0" />
    <path transform="translate(562,110)" d="m0 0" />
    <path transform="translate(561,109)" d="m0 0" />
    <path transform="translate(559,108)" d="m0 0" />
    <path transform="translate(557,106)" d="m0 0" />
    <path transform="translate(669,105)" d="m0 0" />
    <path transform="translate(556,105)" d="m0 0" />
    <path transform="translate(555,104)" d="m0 0" />
    <path transform="translate(720,90)" d="m0 0" />
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
