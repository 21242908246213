<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(289,505)" d="m0 0h2l8 24 8 16 11 16 8 9 2 1v2l4 2 10 8 13 8 15 7 16 5 18 3h24l18-3 21-7 15-8 13-9 10-9 8-8 10-14 10-19 6-18 2-5 6 1 17 8 24 14 17 12 13 10 14 12 24 24 9 11 13 17 10 15 11 19 13 27 8 21 8 28 4 20 3 22 1 14v285l-3 11-8 16-11 12-10 7-11 5-16 3-35 1-3-2v-447h-62v1123l-1 103-3 11-4 9-7 11-9 9-11 7-12 5-10 2h-18l-12-3-12-6-10-8-7-7-8-13-4-11-2-16-1-863-61-1v757l-1 117-3 11-4 9-7 11-9 9-11 7-12 5-10 2h-18l-12-3-12-6-10-8-7-7-8-13-4-11-2-17-1-1214-61-1v446l-1 2h-41l-16-4-9-5-10-8-7-7-8-13-4-11-2-17v-261l1-21 4-30 5-23 8-26 10-25 8-17 11-19 7-11 13-18 11-13 7-8 9-10 17-16 14-11 15-11 17-11 22-12z"/>
    <path transform="translate(1948,803)" d="m0 0h4l1 6v1141l-1 2h-576l-1-1v-904l3-4 26-11 43-18 47-20 88-37 133-56 43-18 40-17 48-20 40-17 41-17zm-416 413-16 2-14 4-16 8-11 8-5 4v2h-2l-9 11-7 11-6 13-4 14-2 16 1 16 4 17 7 16 7 11 11 12 9 8 15 9 16 6 15 3h21l16-3 13-5 15-8 11-9 8-8 8-11 8-16 4-13 2-11v-22l-3-15-5-14-7-13-8-10-5-6h-2v-2l-13-10-16-8-16-5-16-2zm300 11-6 5-7 8-368 368 1 4 3 1 2 4 8 7 1 2h2l2 4h2l2 4h2l2 4h2l2 4h2v2h2v2l6 5 1 2 5-2 6-6v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2 8-8v-2l4-2v-2l4-2v-2l4-2 260-260v-2l4-2v-2l4-2 9-9-1-4-41-41zm-45 245-15 2-14 4-16 8-11 8-5 4v2h-2l-9 11-7 11-7 16-4 18v24l4 18 7 16 7 11 11 12 9 8 15 9 16 6 15 3h21l16-3 13-5 15-8 11-9 8-8 9-13 8-16 4-16 1-5v-23l-3-15-5-14-7-13-8-10-7-8-13-10-16-8-11-4-20-3zm-218 352-1 15v48l60 1 3-1v-63zm128 0-1 19v44h63v-63zm128 0-1 19v44h63v-63z"/>
    <path transform="translate(1180,1123)" d="m0 0h4l1 6v821l-1 2h-562l1-4 8-17 5-19 2-12 1-547 3-3 30-13 47-20 36-15 40-17 171-72 126-53 86-36zm-315 253-1 2v62h-63l-1 29v58l1 21 4 18 5 12 8 14 12 13 9 8 13 8 14 6 18 4 20 2 9 4 8 7 5 10 1 3 1 39-38-1-11-4-8-7-6-12-2-8h-63l2 18 6 20 9 16 11 13 12 10 9 6 14 7 1 5v64l60 1 3-1 1-62 1-1 62-1v-105l-3-16-5-14-7-13-8-10-7-8-12-9-14-8-14-5-20-3-12-1-10-4-8-7-6-12-1-7v-32l1-1h32l10 2 10 6 6 7 4 10 2 6h62v-10l-3-15-6-16-9-15-12-13-11-9-16-9-5-2-1-2-1-68zm192 192-1 2v61h63v-63zm0 128-1 2v61h63v-63zm0 128-1 2v61l60 1 3-1v-63z"/>
    <path transform="translate(1841,199)" d="m0 0h8l-1 12-42 252-2 13h-4l-14-14v-2l-4-2v-2l-4-2-20-20v-2l-4-2v-2l-4-2v-2l-4-2v-2l-4-2v-2l-4-2-7-7-1-2-4 1-554 554h-372l-1-1v-126l2-2 332-1 8-7 486-486 5-6 8-7 3-3-2-4-27-27v-2l-4-2v-2l-4-2v-2l-4-2v-2l-4-2-4-4v-2l-4-2v-2l-4-2v-2l-3-1-5-6-7-6 1-3 215-36z"/>
    <path transform="translate(408,95)" d="m0 0h17l18 2 21 5 16 6 19 10 16 11 12 11 5 4 9 11 9 12 10 17 8 19 5 18 3 18v34l-3 18-6 20-7 16-8 15-13 18h-2l-2 4-13 13-16 12-15 9-20 9-22 6-20 3h-28l-20-3-20-6-16-7-15-8-12-9-13-11-9-9-11-14-9-14-8-16-6-16-5-21-2-17v-17l2-18 4-18 6-17 9-19 8-12 10-13 8-9h2l1-3 8-7 15-11 17-10 20-8 19-5 12-2z"/>
    <path transform="translate(404,479)" d="m0 0h21l36 3 18 3 1 4-5 17-7 12-11 12-11 7-12 5-10 2h-17l-13-3-12-6-10-8-9-10-8-16-3-11 1-5 25-4z"/>
    <path transform="translate(1790,1535)" d="m0 0 13 2 8 4 7 7 5 9 1 3v16l-6 12-9 8-11 4h-13l-12-5-7-7-6-12-1-11 2-9 6-10 9-7 7-3z"/>
    <path transform="translate(1534,1279)" d="m0 0 13 2 8 4 7 7 5 9 1 3v16l-6 12-7 7-9 4-4 1h-13l-12-5-7-7-6-12-1-11 2-9 6-10 9-7 8-3z"/>
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
