<template>
  <div id="app">
    <!-- <h1 class="cust_card card bg-red-900 text-white p-6 rounded-xl shadow-2xl mt-28">{{secondVal}}</h1> -->
    <router-view />
    <!-- <v-idle v-show="false"
    @idle="onidle"
    @remind="onremind"
    :reminders="[60, 10]"
    :loop="false"
    :duration="300" /> -->
    <loader
        v-if="$store.getters.showLoader"
        v-bind:data="$store.getters.loaderMessage"
      ></loader>
      <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
export default {
  name: "App",
  components: {
    Loader,
    snakBar
  },
  data () {
    return {
      localData: null,
      secondVal: 0,
      timer: 0,
      execution: '',
      currSeconds: 0,
    }
  },
  // computed () {
  //   this.execution = setInterval(this.timerIncrement(), 1000); 
  //   window.onload = this.resetTimer()
  //   window.onmousemove = this.resetTimer()
  //   window.onmousedown = this.resetTimer()
  //   window.ontouchstart = this.resetTimer()
  //   window.onclick = this.resetTimer()
  //   window.onkeypress = this.resetTimer()
  // },
  mounted () {
    this.$root.$on('setDataManually', (data) => {
      this.localData = JSON.parse(data)
    })
    this.localData = localStorage.getItem('employeeData2')
    // console.log('this.localData', this.localData)
  },
  methods: {
    // onidle() {
    //   console.log('this.localData', this.localData)
    //   if (this.localData !== null) {
    //     console.log('click 2')
    //     removeCookies('authToken')
    //     localStorage.clear()
    //     this.$store.dispatch('GetEmployeePermission', true)
    //     this.$router.push({name: 'login'})
    //     location.reload()
    //   }
    // },
    // onremind(time) {
    //   if (this.localData !== null) {
    //     console.log('click 1', time)
    //     let Temp = "❕ Session Expired in " + time + " Second" 
    //     this.$store.dispatch('SetAlert', {showAlert: true, message: Temp, color: 'orange'})
    //   }
    //   // alert seconds remaining to 00:00
    // },
    // resetTimer() { 
    //   this.currSeconds = 0
    //   this.secondVal = 0
    // },
    // timerIncrement() { 
    //   this.currSeconds = this.currSeconds + 1; 
    //   this.secondVal =  this.currSeconds; 
    // } 
  },
  watch: {
  },
  beforeDestroy () {
    this.$root.$off('setDataManually')
  },
}
</script>
