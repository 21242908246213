
import router from './router/index'
import store from './store'
import { getCookies, removeCookies } from '@/utils/cookies'
router.beforeEach(async(to, from, next) => {
    document.title = 'Dashboard'
    var authToken = getCookies('authToken')
    if (authToken === undefined || authToken === null) {
        if (to.path !== '/login') {
            localStorage.setItem('routeNextToB', to.fullPath)
            next({
                name: 'login'
            })
        } else {
            next()
        }
    } else {
        if (to.path !== '/login') {
            if (store.getters.isAdmin === '' || store.getters.latitude === '') {
                console.log('store.getters.latitude', store.getters.latitude)
                store.dispatch('GetProjectSpecificPermission').then(res => {
                    console.log(res)
                    next()
                }).catch(() => {
                    removeCookies('authToken')
                    localStorage._ruData55 = JSON.stringify(to)
                    next('/')
                })
            } else {
                next()
            }
        } else {
            next({
                name: 'Dashboard'
            })
        }
    }
})