export const AdminPanel = { // dashboard object start
    path: '/admin',
    component: () => import('@/View/Users/Layout/layout.vue'),
    hidden: true,
    redirect: '/setting',
    name: 'AdminPanel',
    meta: {
      title: 'Admin Panel',
      breadCrumb: 'Admin',
      permissionList: ['abc', 'def']
    },
    children: [
      {
        path: '/setting',
        name: 'ProfileDetail',
        component: () => import('@/View/Users/setting/settingDashboard.vue'),
        meta: {
          title: 'ProfileDetail',
          breadCrumb: 'Dashboard',
          selected: false
        }
      },
      {
        path: '/user',
        name: 'Role',
        component: () => import('@/View/Users/Staff/main.vue'),
        redirect: '/user/list',
        meta: {
          icon: 'fas fa-users',
          color: 'primary',
          title: 'User List',
          breadCrumb: 'role',
          loadPage: 'UserList',
          routeIcon: 'usertagSvgVue',
          routIconSize: {height: '40px', width: '40px'},
          selected: false,
          permissionList: ['abc', 'def']
        },
        children: [
          {
            path: '/user/list',
            name: 'UserList',
            component: () => import('@/View/Users/Staff/list.vue'),
            meta: {
              title: 'Role List',
              breadCrumb: 'role',
              loadPage: 'UserList',
              routeIcon: 'usertagSvgVue',
              routIconSize: {height: '40px', width: '40px'},
              selected: false
            }
          },
          {
            path: '/user/details/:staffId',
            name: 'staffDetail',
            component: () => import('@/View/Users/Staff/staffDetail.vue'),
            meta: {
              title: 'Staff Detail',
              breadCrumb: 'role',
              loadPage: 'UserList',
              routeIcon: 'usertagSvgVue',
              routIconSize: {height: '40px', width: '40px'},
              selected: false
            }
          },
          {
            path: '/user/add/:staffId',
            name: 'addNewStaff',
            component: () => import('@/View/Users/Staff/addEditStaff.vue'),
            meta: {
              title: 'Add New Staff',
              breadCrumb: 'role',
              loadPage: 'UserList',
              routeIcon: 'usertagSvgVue',
              routIconSize: {height: '40px', width: '40px'},
              selected: false
            }
          },
          {
            path: '/user/edit/:staffId',
            name: 'editStaff',
            component: () => import('@/View/Users/Staff/addEditStaff.vue'),
            meta: {
              title: 'Add New Staff',
              breadCrumb: 'role',
              loadPage: 'UserList',
              routeIcon: 'usertagSvgVue',
              routIconSize: {height: '40px', width: '40px'},
              selected: false
            }
          }
        ]
      },
    ]
}