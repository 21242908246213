<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(1016,307)" d="m0 0h15l9 4 7 7 4 9 1 5v66l22 1 36 4 36 6 31 7 32 9 38 13 24 10 20 9 17 8 22 12 22 13 20 13 17 12 19 14 13 11 10 8 10 9 8 7 16 15 12 12 7 8 13 14 9 11 14 17 10 14 11 15 11 17 3 4 4-1 22-12 21-12 16-9 10-3 11 1 10 5 6 7 4 9v13l-5 10-7 7-27 15-24 14-12 6 4 10 12 26 13 33 11 33 8 29 8 37 6 39 3 32 1 18v54l-3 39-6 42-7 34-9 34-12 37-12 30-15 33-1 6 15 8 23 13 21 12 8 6 6 10 1 3v13l-5 10-7 7-10 4-7 1-13-4-29-16-21-12-10-6-3 1-10 16-11 16-16 21-8 10-24 28-17 17-2 3h-2l-2 4-8 7-7 7-8 7-14 12-11 9-13 10-18 13-27 18-22 13-22 12-28 14-31 13-21 8-31 10-30 8-39 8-35 5-40 4h-5v62l-2 12-6 9-8 6-7 2h-11l-10-4-8-8-4-11v-68l-28-2-27-3-26-4-39-8-29-8-36-12-21-8-21-9-28-13-17-9-24-14-19-12-12-8-18-13-13-10-17-14-14-12-17-16-10-9-7-8-12-12-9-11-11-12-8-10-16-21-13-19-10-15v-2l-10 4-23 13-25 14-10 5-4 1h-12l-9-4-6-5-6-12v-14l5-10 8-7 27-15 23-13 11-6-1-6-12-25-11-27-12-34-8-27-7-28-7-37-4-30-3-35v-70l3-35 6-40 7-34 8-30 14-43 14-34 10-22 3-5-1-5-19-10-24-14-18-10-5-4-6-10-1-3v-13l3-8 8-9 9-4 4-1h7l12 4 24 14 24 13 10 6h3l8-12 13-19 16-21 10-13 10-11 9-11 16-17 20-20 8-7 12-11 11-9 16-13 18-13 17-12 24-15 17-10 26-14 32-15 30-12 36-12 36-10 34-7 33-5 44-4 6-1 1-69 6-12 9-7z"/>
    <path transform="translate(1016,307)" d="m0 0h15l9 4 7 7 4 9 1 5v66l22 1 36 4 36 6 31 7 32 9 38 13 24 10 20 9 17 8 22 12 22 13 20 13 17 12 19 14 13 11 10 8 10 9 8 7 16 15 12 12 7 8 13 14 9 11 14 17 10 14 11 15 11 17 3 4 4-1 22-12 21-12 16-9 10-3 11 1 10 5 6 7 4 9v13l-5 10-7 7-27 15-24 14-12 6 4 10 12 26 13 33 11 33 8 29 8 37 6 39 3 32 1 18v54l-3 39-6 42-7 34-9 34-12 37-12 30-15 33-1 6 15 8 23 13 21 12 8 6 6 10 1 3v13l-5 10-7 7-10 4-7 1-13-4-29-16-21-12-10-6-3 1-10 16-11 16-16 21-8 10-24 28-17 17-2 3h-2l-2 4-8 7-7 7-8 7-14 12-11 9-13 10-18 13-27 18-22 13-22 12-28 14-31 13-21 8-31 10-30 8-39 8-35 5-40 4h-5v62l-2 12-6 9-8 6-7 2h-11l-10-4-8-8-4-11v-68l-28-2-27-3-26-4-39-8-29-8-36-12-21-8-21-9-28-13-17-9-24-14-19-12-12-8-18-13-13-10-17-14-14-12-17-16-10-9-7-8-12-12-9-11-11-12-8-10-16-21-13-19-10-15v-2l-10 4-23 13-25 14-10 5-4 1h-12l-9-4-6-5-6-12v-14l5-10 8-7 27-15 23-13 11-6-1-6-12-25-11-27-12-34-8-27-7-28-7-37-4-30-3-35v-70l3-35 6-40 7-34 8-30 14-43 14-34 10-22 3-5-1-5-19-10-24-14-18-10-5-4-6-10-1-3v-13l3-8 8-9 9-4 4-1h7l12 4 24 14 24 13 10 6h3l8-12 13-19 16-21 10-13 10-11 9-11 16-17 20-20 8-7 12-11 11-9 16-13 18-13 17-12 24-15 17-10 26-14 32-15 30-12 36-12 36-10 34-7 33-5 44-4 6-1 1-69 6-12 9-7zm-5 150-36 2-27 3-36 6-31 7-31 9-31 11-24 10-35 17-18 10-23 14-22 15-19 14-16 13-15 13-10 9-9 9-6 5-7 8-11 11-9 11-9 10-20 26-13 19-13 20-14 24-14 27-14 32-6 15-13 40-10 40-6 34-3 23-2 24-1 24v21l1 26 4 40 6 36 8 35 9 30 11 31 14 32 15 30 14 24 10 16 8 12 13 18 11 14 9 11 12 14 14 15 22 22 8 7 10 9 11 9 14 11 19 14 15 10 21 13 23 13 28 14 31 13 34 12 28 8 29 7 28 5 23 3 35 3h56l36-3 35-5 34-7 30-8 36-12 32-13 33-16 20-11 23-14 22-15 16-12 13-10 15-13 14-12 30-30 7-8 9-10 8-10 11-14 13-18 9-13 12-19 15-26 16-32 11-26 10-27 10-33 6-24 6-30 4-28 3-34v-63l-3-35-5-35-8-37-10-35-11-32-13-31-16-32-14-25-11-17-8-12-10-14-10-13-13-16-9-11-7-7-7-8-28-28-11-9-12-11-17-13-13-10-33-22-24-14-29-15-29-13-27-10-38-12-33-8-34-6-36-4-35-2z"/>
    <path transform="translate(1011,457)" d="m0 0h25l35 2 36 4 34 6 33 8 38 12 27 10 29 13 22 11 23 13 19 12 19 13 16 12 14 11 14 12 10 9 8 7 28 28 7 8 9 10 11 14 14 18 16 23 11 18 10 17 10 18 11 23 13 31 13 38 10 37 7 35 4 29 3 35v63l-3 34-6 39-8 36-10 34-10 28-10 24-14 29-11 21-15 25-8 12-12 17-9 12-11 14-11 13-11 12-7 8-27 27-11 9-10 9-14 11-17 13-30 20-15 9-16 9-27 14-27 12-29 11-29 9-26 7-34 7-35 5-36 3h-56l-45-4-36-6-30-7-29-8-29-10-32-13-29-14-19-10-20-12-20-13-17-12-18-14-11-9-10-9-8-7-12-11-23-23-7-8-12-14-13-16-13-18-11-16-14-22-10-18-12-23-11-23-11-27-12-36-8-29-7-33-5-34-3-32-1-26v-21l2-36 3-28 6-36 9-38 11-35 8-22 12-28 10-21 12-22 13-22 16-24 11-15 14-18 24-28 9-9 5-6 8-7 14-14 11-9 10-9 17-13 19-14 15-10 21-13 21-12 26-13 20-9 26-10 36-12 40-10 35-6 24-3 24-2zm-6 73-30 2-31 4-26 5-28 7-37 12-27 11-33 16-18 10-16 10-18 12-15 11-16 13-11 9-15 14-27 27-7 8-14 17-14 19-7 10-13 20-13 23-10 19-12 27-11 29-8 26-7 28-6 32-4 36-1 23v30l2 32 5 36 5 25 7 28 12 36 12 29 12 25 11 20 11 18 8 12 10 14 9 12 9 11 12 14 12 13 25 25 11 9 14 12 20 15 21 14 18 11 22 12 31 15 31 12 28 9 32 8 34 6 38 4h63l30-3 27-4 28-6 29-8 32-11 26-11 25-12 23-13 21-13 17-12 12-9 14-11 10-9 8-7 15-14 13-13 7-8 13-15 13-16 12-17 10-15 12-20 10-18 11-22 13-31 12-36 7-27 6-29 5-38 2-39-1-38-3-32-6-36-7-29-9-29-7-20-11-26-11-23-13-23-11-18-13-19-13-17-13-16-12-14-34-34-11-9-13-11-19-14-16-11-19-12-25-14-32-15-27-11-34-11-38-9-23-4-37-4-22-1z" fill="#FBFCFD"/>
    <path transform="translate(850,806)" d="m0 0 10 1 39 6 34 4 40 3 28 1h46l42-2 42-4 49-7 12-2h6l9 36 8 41 5 37 3 30 2 27v16l-1 1h-401v-19l3-39 5-40 6-35 8-36z" fill="#69A9F1"/>
    <path transform="translate(824,1054)" d="m0 0h400l1 1v14l-3 43-5 41-7 40-8 34-4 15-16-2-31-5-35-4-36-3-25-1h-60l-41 2-42 4-35 5-23 4-4-1-6-21-8-37-6-37-4-34-3-38-1-18z" fill="#69A9F1"/>
    <path transform="translate(1185,481)" d="m0 0 12 3 32 11 28 12 25 12 26 14 21 13 22 15 16 12 14 11 14 12 10 9 8 7 28 28 7 8 9 10 11 14 14 18 16 23 11 18 10 17 10 18 11 23 13 31 13 38 10 37 7 35 4 29 3 35v63l-3 34-6 39-8 36-10 34-10 28-10 24-14 29-11 21-15 25-8 12-12 17-9 12-11 14-11 13-11 12-7 8-27 27-11 9-10 9-14 11-17 13-30 20-15 9-16 9-27 14-27 12-21 8-4-2v-3l3-1 1-3 11-6 18-11 5-3 23-16 10-8 9-7h2v-2l9-7 11-9 10-9 8-7 10-9 30-30 7-8 12-14 11-14 11-15 14-21 17-28 12-22 10-19 11-24 10-26 9-27 8-28 8-38 4-27 3-32 2-40v-58l-2-17-1-20-1-4-2-22-5-24-5-21-9-31-12-35-10-24-9-20-10-19-12-22-5-7-6-11-18-27-20-26-9-11-13-15-6-6v-2h-2l-7-8-11-12-6-5-5-5-5-4-5-5v-2l-4-2-9-8-14-11-4-3v-2l-6-3v-2l-5-2-13-10-12-8-13-9-5-3v-2l-3-1v-2l8 2 16 8 7 3 4 2v2l4-2-5-3-4-2-2-2-6-2-1-2-9-3-10-5-1 2v-2h-3v-2l-16-4-10-5-11-5-11-4-5-3z" fill="#E1E3E4"/>
    <path transform="translate(220,447)" d="m0 0h18l20 3 17 5 17 8 12 8 10 8 7 6v2h2l11 14 9 14 7 15 6 21 2 14v24l-4 21-5 15-8 17-9 13-11 13-11 10-15 10-16 8-18 6-15 3-8 1h-20l-19-3-21-7-16-8-14-10-12-11-10-11-10-15-8-16-6-20-2-10-1-10v-16l3-21 5-17 9-19 8-12 8-10 13-13 17-12 16-8 13-5 12-3z"/>
    <path transform="translate(1810,447)" d="m0 0h19l20 3 19 6 16 8 12 8 11 9 10 10 11 15 9 16 7 19 3 14 1 7v29l-3 17-7 21-8 15-9 13-12 13-11 10-18 11-13 6-15 5-15 3-9 1h-19l-19-3-17-5-19-9-17-12-12-11-9-11-9-13-8-16-5-15-4-20v-29l4-20 6-17 7-14 8-12 13-15 10-9 13-9 12-7 19-7 18-4z"/>
    <path transform="translate(1015,1789)" d="m0 0h18l16 2 21 6 11 5 11 6 10 7 13 11 11 12 10 15 9 19 5 16 3 17v26l-3 18-7 21-10 19-10 13-11 12-14 11-15 9-20 8-19 5-5 1h-31l-26-7-15-6-14-8-12-9-12-11-10-12-8-13-8-16-6-20-2-11-1-21 2-18 5-19 6-15 8-14 9-12 9-10 11-10 15-10 16-8 15-5 15-3z"/>
    <path transform="translate(216,1342)" d="m0 0h26l18 3 21 7 15 8 14 10 13 12 11 13 8 13 8 17 6 21 2 13v25l-5 25-8 20-8 14-10 13-15 15-14 10-15 8-16 6-17 4-8 1h-28l-17-3-16-5-16-8-14-9-14-12-10-11-12-18-7-15-6-19-3-21v-15l3-22 6-19 8-16 7-11 9-11 5-6 8-7 12-9 10-6 12-6 15-5 14-3z"/>
    <path transform="translate(1805,1342)" d="m0 0h26l19 3 21 7 17 9 12 9 10 9 9 9 12 17 10 21 5 18 2 12v29l-2 13-5 18-7 15-8 14-8 10-11 12-14 11-16 9-11 5-16 5-18 3h-28l-17-3-18-6-19-10-11-8-10-9-10-10-11-16-8-15-7-21-3-16v-30l4-20 7-19 9-16 8-11 9-10 8-8 15-11 19-10 15-5 14-3z"/>
    <path transform="translate(1e3)" d="m0 0h36l20 5 16 6 14 7 14 10 12 11 9 10 10 15 8 16 5 15 3 14 1 7v26l-3 18-7 21-9 17-8 11-9 11h-2l-1 3-11 9-15 10-17 8-20 6-11 2-20 1-19-2-19-5-16-7-13-7-13-10-17-17-9-13-6-10-8-20-4-16-2-18v-10l2-17 4-17 6-15 8-15 12-16 14-14 17-12 15-8 21-7z"/>
    <path transform="translate(1014,590)" d="m0 0h19l19 4 16 7 14 8 14 11 12 11 11 12 11 14 9 13 13 21 12 23 13 29 1 5-13 3-36 5-41 4-39 2h-50l-37-2-34-3-38-5-18-3-2-1 1-5 9-21 8-16 12-22 13-20 9-12 9-11 12-13 11-10 15-11 19-10 15-5z" fill="#69A9F1"/>
    <path transform="translate(996,1286)" d="m0 0h54l38 2 33 3 38 5 18 3 1 3-11 25-8 16-10 19-11 17-10 14-8 10-11 12-13 13-13 10-15 9-17 7-19 4h-17l-18-4-19-8-11-7-12-9-10-9-15-15-11-14-10-14-11-18-10-18-10-21-8-18v-4l37-6 37-4 28-2z" fill="#69A9F1"/>
    <path transform="translate(685,752)" d="m0 0 12 5 23 10 29 11 28 9 13 4 2 3-5 19-8 36-7 41-5 43-3 37-1 22-2 2h-169l-1-1 1-15 6-38 6-27 9-29 9-24 8-19 8-16 11-20 13-21 13-18z" fill="#69A9F1"/>
    <path transform="translate(1359,753)" d="m0 0 5 1 12 16 11 16 10 16 14 25 10 21 13 33 9 30 7 30 5 30 1 10v13h-170l-1-3-3-46-4-39-7-47-9-42-6-23 1-3 26-8 35-12 24-10 16-7z" fill="#69A9F1"/>
    <path transform="translate(592,1054)" d="m0 0h169l2 5 2 34 4 43 6 42 7 36 6 26 3 10v6l-8 3-38 12-31 12-24 11-5 1-10-13-13-18-12-20-8-14-12-23-12-29-7-20-7-23-7-32-4-25-2-23z" fill="#69A9F1"/>
    <path transform="translate(1287,1054)" d="m0 0h169v12l-3 25-7 36-9 31-10 28-9 20-8 16-10 19-13 21-12 17-12 16-5-1-15-7-24-10-34-12-26-8-3-2 4-16 9-40 7-41 4-32 3-34 2-35z" fill="#69A9F1"/>
    <path transform="translate(221,507)" d="m0 0h15l15 3 16 8 10 8 8 9 8 14 4 12 1 5v21l-4 15-7 13-9 11-11 9-14 7-15 4h-19l-15-4-14-7-11-9-10-13-7-15-3-14v-13l2-12 5-14 9-13 4-5 11-9 16-8z" fill="#FAFCFD"/>
    <path transform="translate(217,1402)" d="m0 0h22l13 3 14 7 10 8 8 8 8 14 4 10 2 9v20l-3 13-8 16-10 12-12 9-13 6-12 3-16 1-17-3-16-8-10-8-9-10-8-14-4-13-1-7v-13l3-15 6-14 10-13 10-9 10-6 14-5z" fill="#FBFCFD"/>
    <path transform="translate(1811,507)" d="m0 0h15l15 3 16 8 9 7 8 8 8 13 5 14 1 5v23l-3 12-8 16-11 12-7 6-11 6-11 4-9 2h-19l-15-4-15-8-12-11-8-11-5-10-4-13-1-16 3-17 5-11 6-10 11-12 10-7 10-5 11-3z" fill="#FBFCFD"/>
    <path transform="translate(1014,1849)" d="m0 0h19l16 4 13 7 10 8 9 10 8 15 4 14 1 13-3 19-8 16-8 11-10 9-14 8-14 4-7 1h-14l-14-3-16-8-9-7-7-7-7-11-5-10-3-11-1-6v-13l3-14 7-16 8-10 9-9 13-8 14-5z" fill="#E1E3E4"/>
    <path transform="translate(1807,1402)" d="m0 0h23l15 4 13 7 11 9 8 10 8 16 3 12v23l-4 14-7 13-7 9-10 9-12 7-17 5-16 1-13-2-14-5-11-7-9-8-9-12-7-16-3-15 1-17 5-16 7-12 9-10 9-8 16-8z" fill="#FAFCFD"/>
    <path transform="translate(1014,60)" d="m0 0h18l11 2 13 5 12 8 11 11 8 14 4 10 3 16-1 14-4 15-7 13-10 12-12 9-12 6-17 4h-15l-16-4-14-7-10-8-9-10-8-15-4-13-1-6v-13l3-14 5-12 7-11 7-8 12-9 13-6z" fill="#FBFCFD"/>
    <path transform="translate(1287,1054)" d="m0 0h169v12l-3 25-7 36-9 31-10 28-9 20-8 16-10 19-13 21-12 17-12 16-5-1-15-7-24-10 3-1 1-5 6-8 14-25 8-15 14-32 8-23 7-23 8-36 4-25 2-16v-12l-106 1-2 19-2 29h-1v-13l2-35z" fill="#4394ED"/>
    <path transform="translate(1359,753)" d="m0 0 5 1 12 16 11 16 10 16 14 25 10 21 13 33 9 30 7 30 5 30 1 10v13h-170l1-2h38l46-1h24l-3-22-7-40-6-28-8-27-9-25-7-17-8-16-12-23-9-14-1-3h-2v-2h-4l-1-3 24-10 16-7z" fill="#4394ED"/>
    <path transform="translate(1008,1850)" d="m0 0h23v1h-7l-1 3-8 4v5l5 8 8 22 3 14v22l-3 15-7 19-8 14-2 5 5 4-1 1-10-1-12-5-10-6-10-9-8-10-7-14-3-11-1-6v-13l3-14 7-16 8-10 9-9 13-8z" fill="#FBFCFD"/>
    <path transform="translate(1040,65)" d="m0 0 1 2 7 2v-4l6 1 14 9 11 11 8 14 4 10 3 16-1 14-4 15-7 13-10 12-12 9-12 6-17 4h-15l-10-2 4-2 2-7 7-12 8-21 3-15v-22l-4-17-6-16-8-16 9-3z" fill="#E1E3E4"/>
    <path transform="translate(1818,514)" d="m0 0h11l11 2 8 3 11 7 10 10 2 1v-2l-2-1 5-1 8 13 5 14 1 5v23l-3 12-8 16-11 12-7 6-11 6-11 4-9 2h-19l-5-1v-1l7-1-3-1-1-5 8-16 6-15 3-11 1-7v-21l-3-16-4-12-8-16-2-5h2v-2z" fill="#E1E3E4"/>
    <path transform="translate(228,514)" d="m0 0h13l19 6 9 7 6 8 6 7 5 2 2-1-1 5 4-3 5 13 2 8v21l-4 15-7 13-9 11-11 9-14 7-15 4h-19l-5-1 4-2-1-1 1-8 8-16 6-17 3-15v-18l-3-16-6-17-8-15v-2h2v-2z" fill="#E1E3E4"/>
    <path transform="translate(231,1408)" d="m0 0h12l9 3 8 4 9 7 6 8 6 5 3 5 4 1v4l4 5v5h2v-6l2 3 2 9v20l-3 13-8 16-10 12-12 9-13 6-7 2-21-1-8-4 1-4 7-12 7-18 4-19v-18l-3-16-7-20-6-11v-5l5-2z" fill="#E2E3E4"/>
    <path transform="translate(1824,1408)" d="m0 0 10 1 12 4v2l4 1 6 3v2l3 1 9 11 6 7 2 6 4 8 3 9 2 16 1 2 1-21h1v23l-4 14-7 13-7 9-10 9-12 7-12 4-20-1-4-2-6-1 1-5 8-14 8-21 2-16v-16l-2-14-6-18-8-16 1-4 5-2z" fill="#E2E3E4"/>
    <path transform="translate(1204,204)" d="m0 0 11 1 16 8 27 15 26 15 10 9 4 8 1 3v9l-3 9-6 8-8 5-9 2-11-1-16-8-28-16-25-14-9-8-4-9-1-4v-8l4-10 7-8 9-5z" fill="#000101"/>
    <path transform="translate(1633,1546)" d="m0 0 11 1 8 4 5 5 5 7 2 8v8l-4 10-6 7-13 8-29 16-23 13-9 4-4 1h-8l-11-4-5-4-6-10-2-7v-7l4-11 6-7 26-15 41-23 7-3z" fill="#000101"/>
    <path transform="translate(1586,418)" d="m0 0 12 2 21 11 21 12 26 15 8 7 5 10 1 9-2 9-7 10-10 6-4 1h-12l-17-8-24-14-24-13-10-7-7-8-3-8v-10l4-10 7-8 10-5z" fill="#000101"/>
    <path transform="translate(1396,311)" d="m0 0 9 1 16 8 29 16 26 15 6 5 6 10 1 3v11l-4 10-6 7-9 5-4 1h-10l-8-3-21-11-17-10-27-15-7-6-5-9-2-11 2-8 3-6 6-7 10-5z" fill="#000101"/>
    <path transform="translate(771,1752)" d="m0 0h12l12 5 27 15 28 16 10 7 6 8 3 10-1 10-4 8-4 5-10 6-3 1h-13l-9-4-17-9-17-10-24-13-9-7-6-7-3-8v-11l4-9 7-8 7-4z" fill="#000101"/>
    <path transform="translate(579,1645)" d="m0 0h15l16 8 22 12 21 12 13 8 6 5 6 12v12l-4 9-7 8-8 4-5 1h-8l-10-3-27-15-21-12-16-9-7-6-6-12-1-7 2-9 6-10 8-6z" fill="#000101"/>
    <path transform="translate(393,1537)" d="m0 0 11 1 16 8 25 14 23 13 10 7 7 8 3 10v7l-3 9-6 8-8 5-8 2h-8l-13-5-16-9-28-16-19-11-7-8-4-11v-7l3-10 9-10 9-4z" fill="#000101"/>
    <path transform="translate(659,303)" d="m0 0h13l10 4 7 7 4 8 1 4v9l-3 9-9 10-21 12-45 25-8 3h-12l-9-4-6-5-6-11-1-11 4-11 7-8 15-9 25-14 21-12z" fill="#000001"/>
    <path transform="translate(850,196)" d="m0 0h13l9 4 6 5 5 9 1 3v13l-6 12-9 7-52 29-15 8-4 1h-11l-8-3-9-8-4-8-1-3v-12l6-12 10-8 27-15 23-13 15-8z" fill="#000101"/>
    <path transform="translate(470,410)" d="m0 0h12l12 6 7 9 2 6 1 9-3 10-4 6-7 6-21 12-29 16-16 9-6 2h-12l-10-4-7-7-4-8-1-3v-11l4-10 8-8 28-16 25-14 12-7z" fill="#000101"/>
    <path transform="translate(1443,1653)" d="m0 0 13 2 9 6 6 8 2 6v13l-4 9-5 5-8 6-52 29-14 7-5 1h-7l-10-3-8-7-6-12v-12l4-9 6-7 17-10 21-12 23-13 13-6z" fill="#000001"/>
    <path transform="translate(1248,1761)" d="m0 0h14l9 4 7 7 4 8 1 3v11l-4 9-7 8-17 10-27 15-21 12-7 3-5 1h-9l-10-4-6-5-6-12-1-6 2-11 5-8 7-6 26-15 25-14 14-8z" fill="#000101"/>
    <path transform="translate(226,1165)" d="m0 0 11 1 8 4 7 7 4 9 1 6v72l-2 11-6 9-8 6-7 2h-12l-9-4-6-5-6-12-1-13v-61l1-11 5-10 8-7 7-3z" fill="#010204"/>
    <path transform="translate(221,952)" d="m0 0h14l10 4 7 7 4 9 1 6v70l-2 13-6 9-6 5-7 3-9 1-9-2-9-6-6-8-2-7-1-13v-60l2-13 5-9 7-6z" fill="#010204"/>
    <path transform="translate(222,738)" d="m0 0h13l8 3 8 7 5 10 1 6v71l-1 10-5 9-9 8-6 2h-15l-9-4-8-9-3-8-1-12v-19l1-53 4-9 5-6 9-5z" fill="#010203"/>
    <path transform="translate(1814,1183)" d="m0 0h10l10 4 8 7 4 8 1 4v81l-4 10-7 8-8 4-4 1h-11l-9-4-8-7-4-9-1-4v-80l4-10 7-8 8-4z"/>
    <path transform="translate(1818,755)" d="m0 0 12 2 9 6 6 9 2 6v81l-4 11-5 6-12 6h-14l-9-4-7-7-4-8-1-5v-80l4-10 5-6 8-5z" fill="#000001"/>
    <path transform="translate(1815,969)" d="m0 0h8l11 4 7 6 5 9 1 4v81l-3 9-8 9-8 4-5 1h-9l-9-3-9-8-4-9-1-4v-80l4-10 6-7 9-5z"/>
    <path transform="translate(1176,618)" d="m0 0 5 1 30 13 20 10 27 16 16 11 16 12 16 13 10 9 2 4-24 10-28 10-26 8-3-1-12-28-11-24-14-26-10-16-9-13-5-7z" fill="#4394ED"/>
    <path transform="translate(869,618)" d="m0 0h3l-2 4-14 21-12 20-14 27-10 22-9 22-2 1-35-11-28-10-15-6-2-3 10-9 11-9 18-14 27-18 24-14 28-14z" fill="#68A9F1"/>
    <path transform="translate(1237,1313)" d="m0 0 9 2 30 9 31 12 11 5-2 4-11 10-10 8-17 13-27 18-21 12-26 13-24 10-4 1 1-4 10-14 12-19 8-14 8-15 12-26 8-19z" fill="#4394ED"/>
    <path transform="translate(807,1313)" d="m0 0 4 1 13 31 14 29 13 23 22 33-5-1-24-10-28-14-19-11-14-9-20-14-17-14-11-9-5-5v-3l31-12 33-11z" fill="#68A9F1"/>
    <path transform="translate(1195,645)" d="m0 0 5 1 16 9v2l4 2 11 9 9 8 7 6 5 5v2l4 2v2l4 2v2l4 2 12 12 3 4v5h-2v3l-24 8-13 4-3-1-12-28-11-24-14-26-5-8z" fill="#68A9F1"/>
    <path transform="translate(1237,1313)" d="m0 0 9 2 8 3 5 5 5 2 12 5 2 1-1 4-6 7-8 7-10 10-8 7-12 11-20 16-4 3h-4l-1 3-4-1-6 8-5 5 2-5 14-23 16-32 10-23z" fill="#68A9F1"/>
    <path transform="translate(1308,539)" d="m0 0 6 2 2 2 7-1 28 19 16 12 14 11 14 12 3 3 3 9-4-1-2-1v-2l-4-2-13-13-5-3v-2h-3v-2l-5-2-8-7-10-5-2-3-9-4-5-3-3-3-6-3v-2l-4-1-1-2-7-3z" fill="#F1F5F9"/>
    <path transform="translate(1043,1853)" d="m0 0h6l13 7 10 8 9 10 8 15 2 11v20h-2l-2-15-5-12-2-6-16-20v-2l-4-2-7-5-12-5-3-2z" fill="#F3F7FB"/>
    <path transform="translate(1169,727)" d="m0 0 2 2 7 17v2l-13 3-36 5-41 4-39 2h-50l-37-2-14-1v-1h16l27 1h72l23-1 8-1 3-2h21l12-2 4-2h11l13-2 1-2 8-1 1-3z" fill="#4394ED"/>
    <path transform="translate(241,1403)" d="m0 0 8 1 14 6 12 9 9 9 8 14 2 4v9h-2l-1-7-3-1-2-4 1-1-4-1-3-6-3-2v-2l-3-1-8-10-9-6-13-4-2-2-9-1-1-3z" fill="#F1F5FA"/>
    <path transform="translate(762,973)" d="m0 0h1v19l-2 2h-169v-1l39-1 76-1h54z" fill="#4394ED"/>
    <path transform="translate(1883,594)" d="m0 0 2 1-1 7-7 14-11 12-7 6-5 3h-5l3-5 4-3 3-1v-2l9-7 1-4h2l2-5 4-5h3l1-9z" fill="#EBF2F9"/>
    <path transform="translate(1086,151)" d="m0 0h2l-2 10-7 11-7 8-12 9-3-1-1-3h3l1-3h2v-2l5-4 7-8 2-1 6-11 3-2z" fill="#EDF3FA"/>
    <path transform="translate(1876,543)" d="m0 0 1 2 4-1 5 12 1 4v27l-3-4-1-2-2-17-2-4v-4h-2l-1-8-1-4z" fill="#F3F6FA"/>
    <path transform="translate(292,599)" d="m0 0 1 2-3 9-7 10-8 9-11 8-4-1 2-4 4-3h2l2-4 6-7 10-10h2l2-7z" fill="#E9F1F9"/>
    <path transform="translate(291,1496)" d="m0 0h1l-1 7-7 11-7 8-9 7-3-1v-3h2l3-5 5-5h2l1-3h2l1-5h2l1-4 5-5z" fill="#E6EFF9"/>
    <path transform="translate(1147,1295)" d="m0 0 12 1 18 3 1 3-5 12-6-4-1-7-5-4h-14z" fill="#4394ED"/>
    <path transform="translate(869,618)" d="m0 0h3l-2 4-5 7h-2l-4 5-4-1v-2h-8v-2l-2-1z" fill="#4494ED"/>
    <path transform="translate(1851,517)" d="m0 0h5l10 8 7 7-1 3-1 2-4-2-8-8-7-5-3-4z" fill="#F4F7FB"/>
    <path transform="translate(1882,1488)" d="m0 0h3l-1 9-7 13-4 6h-3l-1-5h2l1-4 4-7 4-8z" fill="#ECF3FA"/>
    <path transform="translate(991,1224)" d="m0 0h42l22 1v1l-60 1h-24v-1l18-1z" fill="#4394ED"/>
    <path transform="translate(859,1413)" d="m0 0 4 2 9 13 1 2-5-1-10-4-1-5z" fill="#59A0EF"/>
    <path transform="translate(1087,1939)" d="m0 0h2l-1 7-6 11-4 5h-2v-7l5-6 3-3z" fill="#EAF1F9"/>
    <path transform="translate(1083,96)" d="m0 0h2l5 11v8l-2 1-2-7-5-7-1-4z" fill="#F2F7FC"/>
    <path transform="translate(786,1236)" d="m0 0 2 4 3 10v6l-5 2h-3l1-5h2v-11l-1-5z" fill="#4394ED"/>
    <path transform="translate(777,788)" d="m0 0 7 1 7 3 1 2-3 10h-1l-2-9h-3v-5l-6-1z" fill="#4394ED"/>
    <path transform="translate(740,1336)" d="m0 0 3 1-7 8-2 1-5-5z" fill="#4494ED"/>
    <path transform="translate(1322,542)" d="m0 0 11 7 5 4-4 1-4-2v-2l-5-1-3-3z" fill="#CEDBE9"/>
    <path transform="translate(1065,75)" d="m0 0 4 1 7 7-2 2-5-1-1-2-3-1 1-2 2 1z" fill="#F1F5F8"/>
    <path transform="translate(259,1530)" d="m0 0h4v2l-11 5-9 2-1-2 13-4z" fill="#CEE1F5"/>
    <path transform="translate(241,1403)" d="m0 0 8 1-2 2-5 1h-9l-1-3z" fill="#D6DDE5"/>
    <path transform="translate(1013,1984)" d="m0 0h8v2h10l-1 2h-14z" fill="#C7DFF9"/>
    <path transform="translate(1851,517)" d="m0 0h5l5 5-1 2 2 1h-3v-2l-7-2-3-3z" fill="#DCE3EB"/>
    <path transform="translate(859,1414)" d="m0 0 4 4 2 5-7 2-1-5z" fill="#4695ED"/>
    <path transform="translate(291,1442)" d="m0 0 3 4v9h-2l-1-7h-2v-5z" fill="#E5EDF5"/>
    <path transform="translate(1087,102)" d="m0 0 2 2 1 3v8l-2 1-2-7-1-6z" fill="#E8EFF6"/>
    <path transform="translate(875,1298)" d="m0 0h7l-2 2-8 1 2 5-2 1-2-4v-4z" fill="#4394ED"/>
    <path transform="translate(1113,1291)" d="m0 0 17 1-1 2h-13z" fill="#4997ED"/>
    <path transform="translate(1063,1973)" d="m0 0v3l-8 5h-5l2-1v-2l5-2h3v-2z" fill="#E1EBF5"/>
    <path transform="translate(1337,554)" d="m0 0 5 1 6 4-4 2-2-1v-2l-4-1-2-2z" fill="#D6E0EA"/>
    <path transform="translate(1198,1397)" d="m0 0 2 2-2 1-3 5-5 6v-3l6-10z" fill="#61A5F0"/>
    <path transform="translate(1087,1939)" d="m0 0h2l-1 7-3 4-1-4z" fill="#D9E8F8"/>
    <path transform="translate(1831,1403)" d="m0 0 8 1-3 2h-11l-1-2z" fill="#D2DCE7"/>
    <path transform="translate(1854,631)" d="m0 0 3 1-1 4-7 1 3-5z" fill="#F0F5FA"/>
    <path transform="translate(739,696)" d="m0 0 2 1-1 5-4 1-2 2-1-3z" fill="#4897ED"/>
    <path transform="translate(265,631)" d="m0 0h3l-1 4-3 2-4-1 1-3z" fill="#E0EAF5"/>
    <path transform="translate(290,548)" d="m0 0 3 1 1 3v8h-2l-2-7z" fill="#EFF4FA"/>
    <path transform="translate(275,1515)" d="m0 0m-1 1m-1 1m-1 1m-1 1m-1 1m-1 1m-1 1 2 2-1 4-4 1-1-3 3-1z" fill="#F6F8FB"/>
    <path transform="translate(1883,594)" d="m0 0 2 1-1 7h-3v-6z" fill="#E7F0F9"/>
    <path transform="translate(1308,539)" d="m0 0 6 2 3 4h-4l-3-1z" fill="#FCFCFC"/>
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
