import * as config from '../config.js'
const getters = {
  FCMSenderId: () => '612268085303',
  liveServerUrl: () => config.API_ROOT,
  wpStatusTypeList: () => [{displayText: 'Open', id: 1, isSelected: true}, {displayText: 'Working', id: 2, isSelected: true}, {displayText: 'Waiting', id: 3, isSelected: false}, {displayText: 'On Hold', id: 5, isSelected: false}, {displayText: 'Closed', id: 4, isSelected: false}],
  timeSheetIconColor: state => state.user.timeSheetIconColor,
  companyLogo: state => state.system.companyLogo,
  showLoader: state => state.system.showLoader,
  loaderMessage: state => state.system.loaderMessage,
  themeColors: state => state.system.themeColors,
  isFullPage: state => state.system.isFullPage,
  showAlert: state => state.system.showAlert,
  alertMessage: state => state.system.alertMessage,
  alertColor: state => state.system.alertColor,
  systemTopBarColor: state => state.system.systemTopBarColor,
  microEstPath: state => state.system.microEstPath,
  localRepoPath: state => state.system.localRepoPath,
  dateTimeFormate: state => state.system.dateTimeFormat,
  dateFormat: state => state.system.dateTimeFormat !== '' ? state.system.dateTimeFormat.split(' ')[0] : '',
  permission: state => state.user.permission,
  urlList: state => state.user.urlList,
  normalDateFormat: state => state.user.normalDateFormat,
  longDateFormat: state => state.user.longDateFormat,
  shortDateFormat: state => state.user.shortDateFormat,
  projectPermission: state => state.user.projectPermission,
  googleMapKey: state => state.user.googleMapKey,
  showNotification: state => state.system.showNotification,
  noOfDeviceRequest: state => state.user.noOfDeviceRequest,
  uploadProgressValue: state => state.system.uploadProgressValue,
  uniqueNameToAvoidAutocompelte: () => new Date().getTime(),
  // To show download loader
  downloadFileMessage: state => state.system.downloadFileMessage,
  showDownloadLoader: state => state.system.showDownloadLoader,
  // TO cancel download request send to server
  cancelDownloadRequestSource: state => state.system.cancelDownloadRequestSource,
  showUploadLoader: state => state.system.showUploadLoader,
  uploadFileMessage: state => state.system.uploadFileMessage,
  calendarDayHighlightValue: state => state.system.calendarDayHighlightValue,
  firstDayOfTheWeek: state => state.system.firstDayOfTheWeek,
  isApiLoading: state => state.system.isApiLoading,
  globalEmailDialog: state => state.system.globalEmailDialog,
  moduleName: state => state.system.moduleName,
  entitySlug: state => state.system.entitySlug,
  detailId: state => state.system.detailId,
  templateData: state => state.system.templateData,
  showIpExpirePopup: state => state.system.showIpExpirePopup
}
export default getters
// AIzaSyByugLDPS0BnNAcUgK6m_rMIGHfBKvuyxE
