import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import {createPersistedState, createSharedMutations} from 'vuex-electron'
import system from './modules/system'
import user from './modules/user'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    plugins: [
      // createPersistedState(),
      // createSharedMutations()
    ],
    strict: process.env.NODE_ENV !== 'production',
    system,
    user
  },
  getters
})

export default store
