<template>
  <div>
    <div id="myTopnav" class="topNav topNav_card bg-primary2 hover:shadow-md rounded-none" style="overflow: visible !important;z-index: 1000 !important;">
      <div class="menu_iconss bg-staticCol justify-between">
        <div class="dashboard_menus d_menu_icon flex items-center">
          <span v-if="showIcon">
            <img v-if="mobileView"
                @click.stop="smallViewOpenDrawer2()"
                height="20px"
                class="mr-6 cursor-pointer ml-0"
                width="24px"
                src="@/assets/images/svg/menu-icon.svg"
                alt=""
              />
              <img v-if="!mobileView"
                @click.stop="smallViewOpenDrawer2()"
                height="20px"
                class="mr-6 cursor-pointer ml-2"
                width="24px"
                src="@/assets/images/svg/menu-icon.svg"
                alt=""
              />
          </span>
            <div :class="mobileView ? 'heading-4' : 'heading-1'"
              class=" text-white font-semibold cursor-pointer text-center hover:text-gray-200 transition transform delay-50"
              @click="routingMain()"
              style="letter-spacing: 2px;">
              Analytics Portal
            </div>
            <div class="text-center right-3 absolute">
              <div class="cursor-pointer" @click="isOpenValue()">
                <div class="rounded-full border border-gray-50" dark :class="mobileView ? 'h-8 w-8' : 'h-11 w-11'">
                  <img class="rounded-full" :src="profileImage"  v-if="profileImage !== ''">
                  <img :class="mobileView ? 'h-8 w-8' : 'h-11 w-11'" class="rounded-full" src="@/assets/images/avatar.jpg"  v-if="profileImage === ''">
                </div>
              </div>
              <div v-if="isOpen" class="top-12" id="avatar_menu_box">
                <div class=" px-0 pt-0" v-click-outside="onClickOutside">
                  <div class="cust_card bg-white w-52 border border-gray-200">
                    <div class="p-2">
                      <p class="heading-4 text-text1 font-semibold">{{ EmpObj.firstName }} {{ EmpObj.lastName }}</p>
                      <p class="heading-5 text-text2">({{ EmpObj.loginEmail }})</p>
                    </div>
                    <div class="dividerLight"></div>
                    <div v-if="EmpObj.isAdmin" class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click.stop.prevent="routeToAdminPanel">
                      <p>{{checkForAdmin()}}</p>
                    </div>
                    <div v-if="EmpObj.isAdmin" class="dividerLight"></div>
                    <div class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click="LogOut()">
                      <p>Log Out</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- <div id="myTopnav" class="topNav cust_card bg-primary2 hover:shadow-md items-center" style="z-index: 1000 !important;" v-if="!extraSmall">
      <div :class="mobileView ? 'heading-4' : 'heading-1'" class=" text-white font-semibold cursor-pointer text-center py-1 hover:text-gray-200 transition transform delay-50" @click="routingMain()" style="letter-spacing: 2px;">Analytics Portal</div>
        <div class="flex items-center pl-4 lg:pl-6 xl:pl-12 2xl:pl-12" v-if="routesList !== undefined">
          <div 
            class="items-center text-center "
            v-for="item in routesList.children"
            :key="item.path"
          >
            <div :class="`${mobileView ? 'mx-2 p-1' : 'mx-4 p-2'} ${item.meta.selected ? 'shadow-sm  border-b-2 border-white' : ''}`" class="relative hover:bg-white text-white hover:text-text1 transform  cursor-pointer  group transition"  v-if="item.hidden">
              <div class="" @click="routeTo(item.name, item)">
                <span :class="mobileView ? 'heading-6' : 'heading-4'" class="" >{{ item.meta.title }}</span>
              </div>
              <div class="delay-300 invisible group-hover:visible -ml-12 text-left justify-left items-left bg-white absolute top-12 w-56 rounded-sm z-50" style="box-shadow: 0 2px 4px 0 rgb(92 91 91 / 20%)">
                <div v-for="itemA in item.listing" :key="itemA.path"  @click="routeToA(item, itemA.name, item.listing)" class="p-2  heading-5 " :class="itemA.meta.selected ? 'shadow-sm  bg-gray-200 text-white' : 'text-text2 hover:bg-blue-100'">
                  <span class="pl-2" :style="`color:` +  itemA.meta.color + `; !important`">{{ itemA.meta.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center right-3 absolute">
          <div class="cursor-pointer" @click="isOpenValue()">
            <div class="rounded-full border border-gray-50" dark :class="mobileView ? 'h-8 w-8' : 'h-12 w-12'">
              <img class="rounded-full" :src="profileImage"  v-if="profileImage !== ''">
              <img :class="mobileView ? 'h-8 w-8' : 'h-12 w-12'" class="rounded-full" src="@/assets/images/avatar.jpg"  v-if="profileImage === ''">
            </div>
          </div>
          <div v-if="isOpen" class="top-12" id="avatar_menu_box">
            <div class=" px-0 pt-0" v-click-outside="onClickOutside">
              <div class="cust_card bg-white w-52 border border-gray-200">
                <div class="p-2">
                  <p class="heading-4 text-text1 font-semibold">{{ EmpObj.firstName }} {{ EmpObj.lastName }}</p>
                  <p class="heading-5 text-text2">({{ EmpObj.loginEmail }})</p>
                </div>
                <div class="dividerLight"></div>
                <div v-if="EmpObj.isAdmin" class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click.stop.prevent="routeToAdminPanel">
                  <p>{{checkForAdmin()}}</p>
                </div>
                <div v-if="EmpObj.isAdmin" class="dividerLight"></div>
                <div class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click="LogOut()">
                  <p>Log Out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- <div id="myTopnav" class="topNav cust_card bg-primary2 hover:shadow-md items-center" style="z-index: 1000 !important;" v-if="extraSmall">
      <div  class=" text-white font-semibold cursor-pointer text-center py-1 hover:text-gray-200 transition transform delay-50" @click="routingMain()" style="letter-spacing: 2px;">Analytics Portal</div>
        <div class="flex items-center pl-4" v-if="routesList !== undefined">
          <div 
            class="items-center text-center "
            v-for="item in routesList.children"
            :key="item.path"
          >
            <div :class="` ${item.meta.selected ? 'shadow-sm  border-b-2 border-white' : ''}`" class="mx-1 p-1 relative hover:bg-white text-white hover:text-text1 transform  cursor-pointer  group transition"  v-if="item.hidden">
              <div class="" @click="routeTo(item.name, item)">
                <span class="heading-7" >{{ item.meta.title }}</span>
              </div>
              <div class="delay-300 invisible group-hover:visible -ml-12 text-left justify-left items-left bg-white absolute top-12 w-56 rounded-sm z-50" style="box-shadow: 0 2px 4px 0 rgb(92 91 91 / 20%)">
                <div v-for="itemA in item.listing" :key="itemA.path"  @click="routeToA(item, itemA.name, item.listing)" class="p-2  heading-5 " :class="itemA.meta.selected ? 'shadow-sm  bg-gray-200 text-white' : 'text-text2 hover:bg-blue-100'">
                  <span class="pl-2" :style="`color:` +  itemA.meta.color + `; !important`">{{ itemA.meta.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center right-3 absolute">
          <div class="cursor-pointer" @click="isOpenValue()">
            <div class="rounded-full border border-gray-50" dark :class="mobileView ? 'h-8 w-8' : 'h-12 w-12'">
              <img class="rounded-full" :src="profileImage"  v-if="profileImage !== ''">
              <img :class="mobileView ? 'h-8 w-8' : 'h-12 w-12'" class="rounded-full" src="@/assets/images/avatar.jpg"  v-if="profileImage === ''">
            </div>
          </div>
          <div v-if="isOpen" class="top-12" id="avatar_menu_box">
            <div class=" px-0 pt-0 -top-11 absolute right-0" v-click-outside="onClickOutside">
              <div class="cust_card bg-white w-52 border border-gray-200">
                <div class="p-2">
                  <p class="heading-4 text-text1 font-semibold">{{ EmpObj.firstName }} {{ EmpObj.lastName }}</p>
                  <p class="heading-5 text-text2">({{ EmpObj.loginEmail }})</p>
                </div>
                <div class="dividerLight"></div>
                <div v-if="EmpObj.isAdmin" class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click.stop.prevent="routeToAdminPanel">
                  <p>{{checkForAdmin()}}</p>
                </div>
                <div v-if="EmpObj.isAdmin" class="dividerLight"></div>
                <div class="heading-5 p-2 text-text1 font-semibold hover:bg-gray-100 cursor-pointer" @click="LogOut()">
                  <p>Log Out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
// import Vue from 'vue'
// Vue.directive('click-outside', {
//   bind () {
//       this.event = event => this.vm.$emit(this.expression, event)
//       this.el.addEventListener('click', this.stopProp)
//       document.body.addEventListener('click', this.event)
//   },   
//   unbind() {
//     this.el.removeEventListener('click', this.stopProp)
//     document.body.removeEventListener('click', this.event)
//   },

//   stopProp(event) { event.stopPropagation() }
// })
import {removeCookies} from '@/utils/cookies'
import router from '@/router/index.js'
export default {
  directives: {
      clickOutside: vClickOutside.directive
    },
  components: {
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      showIcon: true,
      profileImage: '',
      isOpen: false,
      EmpObj: [],
      isfavorite: false,
      setFavIcon: false,
      menu: false,
      drawerIsOpen: false,
      showGlobalSearch: false,
      showDashboadMenus: false,
      showAddNewWorkMenus: false,
      showHistory: false,
      showAddFavorite: false,
      showAvtarMenu: false,
      mobileView: false,
      extraSmall: false,
      favoriteList: []
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on('isHideButton', (data) => {
      this.showIcon = data
    })
    this.isOpen = false
    this.$route.meta.selected = true
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData2'))
    this.EmpObj = Isadmin
    if (this.EmpObj === null) {
      this.LogOut()
    }
    if (this.EmpObj.profile.medium !== '') {
      this.profileImage = this.EmpObj.profile.medium
    } else {
      this.profileImage = ''
    }
    if (this.$route.meta.module === "project") {
        this.showGlobalSearch = true
      }
    router.afterEach(() => {
      setTimeout(() => {
        this.setNavigation(this.$route.meta.loadPage)
      }, 300)
    })
    this.resizeWindowHandler()
  },
  watch: {
    routesList: {
      handler () {
        this.setNavigation(this.$route.meta.loadPage)
        console.log('routesList 1321 312 321 321 23 43q 5243 5', this.routesList)
        // for (let index = 0; index < this.routesList.length; index++) {
        //   if (this.routesList[index].children.length > 0) {
        //     // console.log('this.routesList[index]', this.routesList[index])
        //     this.routesList[index].children.forEach(b => {
        //       if (!b.hidden && b.meta !== undefined) {
        //         let tempDataListing = []
        //         tempDataListing = b
        //         this.routesList[index].listing.push(tempDataListing)
        //       }
        //     })
        //   }
        // }
        console.log('this.databasedatabasedatabasedatabase', this.routesList)
        // console.log('this.checking here- 123213---------------------------', this.routesList)
      }
    }
  },
  methods: {
    smallViewOpenDrawer2() {
      this.$root.$emit('openDialog', true)
    },
    routeToAdminPanel () {
      console.log('this.routesList', this.routesList)
      // for (let i = 0; i < this.routesList.children.length; i++) {
      //   this.routesList.children[i].meta.selected = false
      //   for (let j = 0; j < this.routesList.children[i].children.length; j++) {
      //     this.routesList.children[i].children[j].meta.selected = false
      //   }
      // }
      if (this.checkForAdmin() === 'Dashboard') {
        this.$router.push({name: 'Dashboard'})
      } else {
        this.$router.push({name: 'ProfileDetail'})
      }
    },
    checkForAdmin () {
      let path = this.$route.path.split('/')
      for (let i = 0; i < path.length; i++) {
        console.log('path[i]', path)
        if (path[i] === 'setting' || path[i] === 'user' || path[i] === 'details'|| path[i] === 'list') {
          return 'Dashboard'
        }
      }
      return 'Setting'
    },
    routingMain () {
      this.$router.push({name: 'Dashboard'})
      for (let i = 0; i < this.routesList.children.length; i++) {
        if ( this.routesList.children[i].name === 'Dashboard') {
          this.routesList.children[i].meta.selected = true
        } else {
          this.routesList.children[i].meta.selected = false
        }
        for (let j = 0; j < this.routesList.children[i].children.length; j++) {
          this.routesList.children[i].children[j].meta.selected = false
        }
      }
    },
    isOpenValue () {
      console.log('open value')
      this.isOpen = !this.isOpen
    },
    onClickOutside () {
      console.log('Close value')
      this.isOpen = false
    },
    setNavigation (value) {
      if (this.routesList !== undefined) {
        console.log('routesList 123 123', this.routesList, this.$route.meta.loadPage)
        for (let index = 0; index < this.routesList.children.length; index++) {
          if (value === this.routesList.children[index].meta.loadPage) {
            this.routesList.children[index].meta.selected = true;
            // this.routesList[index].meta.routIconSize.color = colors.primary;
          } else {
            this.routesList.children[index].meta.selected = false;
            // this.routesList[index].meta.routIconSize.color = colors.text2;
          }
        }
      }
    },
    LogOut () {
      removeCookies('authToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    routeToA (main, path, fullList) {
      console.log('main A', main)
      console.log('path A', path)
      console.log('fullList A', fullList)
      this.$router.push({ name: path })
      // for clear all isselected value for background gray
      console.log('this.routesList', this.routesList)
      for (let IAA = 0; IAA < this.routesList.children.length; IAA++) {
        for (let JJ = 0; JJ < this.routesList.children[IAA].listing.length; JJ++) {
          this.routesList.children[IAA].listing[JJ].meta.selected = false
        }
      }
      // for background color change
      for (let index = 0; index < fullList.length; index++) {
        console.log('fullList[index].meta.title', fullList[index].meta)
        if (path === 'WalmartAcc' && fullList[index].meta.title === 'Walmart') {
          fullList[index].meta.selected = true
        } else if (path === 'AmazonAcc' && fullList[index].meta.title === 'Amazon') {
          fullList[index].meta.selected = true
        } else if (path === 'SAGEAcc' && fullList[index].meta.title === 'SAGE') {
          fullList[index].meta.selected = true
        } else if (fullList[index].meta.title === path) {
          fullList[index].meta.selected = true
        } else {
          fullList[index].meta.selected = false
        }
      }
      console.log('$.meta.route ', this.$route)
      console.log('$loadPage', this.$route.meta.loadPage )
      console.log('for check it is run multiple time or not')
      console.log('$routesList', this.routesList)
    },
    routeTo(path, data) {
      console.log('-----------------------data---------------------', data)
      console.log('path To', path)
      this.$router.push({ name: path })
      for (let index = 0; index < this.routesList.children.length; index++) {
        for (let JJ = 0; JJ < this.routesList.children[index].listing.length; JJ++) {
          this.routesList.children[index].listing[JJ].meta.selected = false
        }
        if (this.$route.meta.loadPage === this.routesList.children[index].meta.loadPage) {
          this.routesList.children[index].meta.selected = true;
        } else {
          this.routesList.children[index].meta.selected = false;
        }
      }
      this.$route.meta.selected = true
      console.log('$.meta.route ', this.$route)
      console.log('$loadPage', this.$route.meta.loadPage )
      console.log('for check it is run multiple time or not')
      console.log('$routesList', this.routesList)
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1378) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 960) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
    beforeDestroy () {
      this.$root.$off('isHideButton')
    },
  },
};
</script>
<style scoped>
.menu_iconss {
  width: inherit;
  padding: 0px 0px 0px 0px;
  display: inherit;
  align-items: center;
}
.row_user_svg {
  height: 25px;
  width: 25px;
}
</style>