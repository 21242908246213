<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(1122,341)" d="m0 0h486l13 4 10 8 406 406 8 11 3 6v675l-7 26-6 15-8 15-9 12-9 10-10 10-13 9-16 9-14 6-15 4-26 3-96 1-10 19-10 15-8 11-11 13-18 18-11 9-17 12-20 12-24 11-18 6-26 6-22 3-25 1-17-1-30-5-21-6-19-7-23-11-18-11-12-9-11-9-13-12-13-14-10-13-12-18-9-16-2-4-53-1h-593l-84 1-11 20-12 18-8 10-13 15-9 9-11 9-17 13-21 13-21 10-22 8-24 6-26 4-11 1h-13l-25-2-23-4-25-7-16-6-23-11-16-10-14-10-14-12-12-11-9-10-11-14-7-10-10-17-7-12-166-1-15-3-12-5-11-8-10-10-8-13-5-13-1-4v-162l7-18 7-11 7-8 12-9 13-6 13-3 21-1 6 1-8-17-7-23-3-22v-630l3-21 5-17 12-25 12-16 18-18 14-10 19-10 17-6 15-3 8-1 30-1h564l116 1 23 4 15 5 16 8 12 8 10 8 11 10 11 14 10 16 7 16 4 12 3 17 1 15v620l-1 18-5 20-10 24-2 3 86 1v-927l4-11 7-9 9-6zm-705 204-41 1-1 7v371l2 9 6 10 9 8 9 4 10 1 11-3 18-8 102-51 1-1 7 1 23 11 19 10 23 11 30 15 19 10 10 4 11 1 9-2 8-4 7-6 7-13 1-10v-303l-1-72zm977 1-10 3-9 7-6 8-3 7-1 7v348l3 10 7 10 9 6 8 3 6 1h30l7-1 50 1h49l15-1 39 1h62l10-1h2l49 1h96l14-2 10-6 7-8 5-12v-13l-4-10-6-8-8-6-7-3-6-1-378-1-1-313-3-9-7-9-8-6-9-3zm-966 751-24 4-23 7-21 10-18 12-13 11-5 4v2l-3 1-7 8-13 17-12 21-9 23-5 22-2 15v27l3 21 6 21 8 20 7 13 12 17 12 14 7 7 14 11 10 7 16 9 17 7 15 5 20 4 9 1h30l24-4 20-6 18-8 14-8 14-10 14-12 8-8 11-14 7-10 11-21 7-19 5-21 2-13v-32l-3-20-6-21-8-19-11-19-12-16-12-13-14-12-15-10-18-10-21-8-20-5-15-2zm1160 0-24 4-20 6-13 6-15 8-12 8-16 13-12 12-13 17-12 21-8 18-5 16-3 15-2 24 1 21 3 18 6 21 8 19 11 19 10 13 11 12 9 9 13 10 13 8 19 10 16 6 21 5 14 2h32l24-4 19-6 23-10 16-10 10-8 12-11 11-11 12-16 9-15 5-11 7-19 5-22 2-18v-20l-3-22-5-20-6-16-11-21-12-17-9-10-5-6-11-9-10-8-16-10-16-8-17-6-16-4-19-3z"/>
    <path transform="translate(383,609)" d="m0 0 4 2 7 7h-3l-8-8z" />
    <path transform="translate(1331,316)" d="m0 0 4 2 7 7h-3l-8-8z" />
    <path transform="translate(564,469)" d="m0 0 2 1-6 6-2-1z" />
    <path transform="translate(1377,333)" d="m0 0 2 1-6 6-2-1z" />
    <path transform="translate(1344,328)" d="m0 0 6 4 2 3-4-2-4-3z" />
    <path transform="translate(1264,333)" d="m0 0v3l-5 4 2-4z" />
    <path transform="translate(1399,764)" d="m0 0 2 1-4 3z" />
    <path transform="translate(397,622)" d="m0 0 4 2h-3z" />
    <path transform="translate(379,604)" d="m0 0 3 2-1 2z" />
    <path transform="translate(1552,949)" d="m0 0v3l-2-1z" />
    <path transform="translate(1423,738)" d="m0 0v3l-2-1z" />
    <path transform="translate(1515,950)" d="m0 0 3 1h-3z" />
    <path transform="translate(388,647)" d="m0 0v3z" />
    <path transform="translate(694,468)" d="m0 0 3 1h-3z" />
    <path transform="translate(383,653)" d="m0 0 2 1-2 1z" />
    <path transform="translate(676,470)" d="m0 0 2 1-2 1z" />
    <path transform="translate(1401,762)" d="m0 0" />
    <path transform="translate(1428,754)" d="m0 0" />
    <path transform="translate(380,718)" d="m0 0" />
    <path transform="translate(1467,338)" d="m0 0 2 1z" />
    <path transform="translate(1125,1577)" d="m0 0" />
    <path transform="translate(1662,952)" d="m0 0" />
    <path transform="translate(1663,951)" d="m0 0" />
    <path transform="translate(1629,951)" d="m0 0" />
    <path transform="translate(1664,950)" d="m0 0" />
    <path transform="translate(1514,949)" d="m0 0" />
    <path transform="translate(1554,947)" d="m0 0" />
    <path transform="translate(1557,945)" d="m0 0" />
    <path transform="translate(1558,944)" d="m0 0" />
    <path transform="translate(1559,943)" d="m0 0" />
    <path transform="translate(1369,805)" d="m0 0" />
    <path transform="translate(1392,772)" d="m0 0" />
    <path transform="translate(1393,771)" d="m0 0" />
    <path transform="translate(1394,770)" d="m0 0" />
    <path transform="translate(1395,769)" d="m0 0" />
    <path transform="translate(1396,768)" d="m0 0" />
    <path transform="translate(1402,761)" d="m0 0" />
    <path transform="translate(1403,760)" d="m0 0" />
    <path transform="translate(1405,758)" d="m0 0" />
    <path transform="translate(1407,756)" d="m0 0" />
    <path transform="translate(1427,753)" d="m0 0" />
    <path transform="translate(1424,737)" d="m0 0" />
    <path transform="translate(1425,736)" d="m0 0" />
    <path transform="translate(1426,735)" d="m0 0" />
    <path transform="translate(388,726)" d="m0 0" />
    <path transform="translate(387,725)" d="m0 0" />
    <path transform="translate(386,724)" d="m0 0" />
    <path transform="translate(382,721)" d="m0 0" />
    <path transform="translate(381,720)" d="m0 0" />
    <path transform="translate(379,717)" d="m0 0" />
    <path transform="translate(712,660)" d="m0 0" />
    <path transform="translate(713,659)" d="m0 0" />
    <path transform="translate(379,658)" d="m0 0" />
    <path transform="translate(380,657)" d="m0 0" />
    <path transform="translate(381,656)" d="m0 0" />
    <path transform="translate(382,655)" d="m0 0" />
    <path transform="translate(385,651)" d="m0 0" />
    <path transform="translate(386,650)" d="m0 0" />
    <path transform="translate(389,646)" d="m0 0" />
    <path transform="translate(390,645)" d="m0 0" />
    <path transform="translate(1423,626)" d="m0 0" />
    <path transform="translate(396,621)" d="m0 0" />
    <path transform="translate(395,620)" d="m0 0" />
    <path transform="translate(394,619)" d="m0 0" />
    <path transform="translate(382,608)" d="m0 0" />
    <path transform="translate(702,475)" d="m0 0" />
    <path transform="translate(590,475)" d="m0 0" />
    <path transform="translate(446,475)" d="m0 0" />
    <path transform="translate(333,475)" d="m0 0" />
    <path transform="translate(701,474)" d="m0 0" />
    <path transform="translate(672,474)" d="m0 0" />
    <path transform="translate(447,474)" d="m0 0" />
    <path transform="translate(334,474)" d="m0 0" />
    <path transform="translate(673,473)" d="m0 0" />
    <path transform="translate(448,473)" d="m0 0" />
    <path transform="translate(335,473)" d="m0 0" />
    <path transform="translate(699,472)" d="m0 0" />
    <path transform="translate(449,472)" d="m0 0" />
    <path transform="translate(336,472)" d="m0 0" />
    <path transform="translate(450,471)" d="m0 0" />
    <path transform="translate(337,471)" d="m0 0" />
    <path transform="translate(697,470)" d="m0 0" />
    <path transform="translate(585,470)" d="m0 0" />
    <path transform="translate(451,470)" d="m0 0" />
    <path transform="translate(338,470)" d="m0 0" />
    <path transform="translate(584,469)" d="m0 0" />
    <path transform="translate(452,469)" d="m0 0" />
    <path transform="translate(582,468)" d="m0 0" />
    <path transform="translate(453,468)" d="m0 0" />
    <path transform="translate(693,467)" d="m0 0" />
    <path transform="translate(581,467)" d="m0 0" />
    <path transform="translate(454,467)" d="m0 0" />
    <path transform="translate(692,466)" d="m0 0" />
    <path transform="translate(1356,339)" d="m0 0" />
    <path transform="translate(1485,338)" d="m0 0" />
    <path transform="translate(1355,338)" d="m0 0" />
    <path transform="translate(1353,336)" d="m0 0" />
    <path transform="translate(1465,335)" d="m0 0" />
    <path transform="translate(1352,335)" d="m0 0" />
    <path transform="translate(1464,334)" d="m0 0" />
    <path transform="translate(1383,329)" d="m0 0" />
    <path transform="translate(1384,328)" d="m0 0" />
    <path transform="translate(1385,327)" d="m0 0" />
    <path transform="translate(1343,327)" d="m0 0" />
    <path transform="translate(1386,326)" d="m0 0" />
    <path transform="translate(1342,326)" d="m0 0" />
    <path transform="translate(1387,325)" d="m0 0" />
    <path transform="translate(1388,324)" d="m0 0" />
    <path transform="translate(1389,323)" d="m0 0" />
    <path transform="translate(1330,315)" d="m0 0" />
    <path transform="translate(1329,314)" d="m0 0" />
    <path transform="translate(1328,313)" d="m0 0" />
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
