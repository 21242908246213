<template>
  <div v-if="routesListNew !== null">
    <div v-if="!mobileView" id="mySidenav" class="sidenav mt-14" style="background-color: #fff;" v-click-outside="hoverOut">
      <div v-click-outside="hoverOut">
        <div
          class="d_icon_box flex items-center hover:bg-gray2  text-text2 hover:text-black p-3 m-2 heading-5 cursor-pointer"
          v-for="item in routesListNew"
          :key="item.path"
          @click="routeTo(item.name)"  :class="item.meta.selected ? ' shadow-sm border-gray2 border text-text2 rounded-md' : ''"
          v-show="item.hidden"
        >
          <div class="d_icon_child -ml-1" >
            <div class="">
              <component  :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
          </div>
          <span class="textOverflow pl-3">{{ item.meta.title }}</span>
        </div>
      </div>
    </div>
    <div v-else class="sv_sidenav_box">
      <div id="sv_smySidenav" class="sv_sidenav" style="background-color: #fff;" v-click-outside="hoverOut">
        <div
          class="d_icon_box flex items-center hover:bg-gray2  text-text2 hover:text-black p-3 m-2 heading-5 cursor-pointer"
          v-for="item in routesListNew"
          :key="item.path"
          @click="routeTo(item.name)"  :class="item.meta.selected ? ' shadow-sm border-gray2 border text-text2 rounded-md' : ''"
          v-show="item.hidden"
        >
          <div class="d_icon_child -ml-1" >
            <div class="">
              <component  :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
          </div>
          <span class="textOverflow text-text2 pl-3">{{ item.meta.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardSvg from '@/assets/images/svg/dashboardSvg.vue'
import accountingSvg from '@/assets/images/svg/accountingSvg.vue'
import cogsSvg from '@/assets/images/svg/cogsSvg.vue'
import consumerSalesSvg from '@/assets/images/svg/consumerSalesSvg.vue'
import dealerNetworkSvg from '@/assets/images/svg/dealerNetworkSvg.vue'
import productionSvg from '@/assets/images/svg/productionSvg.vue'
import shippingSvg from '@/assets/images/svg/shippingSvg.vue'
import {removeCookies} from '@/utils/cookies'
import { getCookies } from "@/utils/cookies";
// import colors from '@/assets/colors.json'
import router from '@/router/index.js'
export default {
  components: {
    dashboardSvg,
    accountingSvg,
    cogsSvg,
    consumerSalesSvg,
    dealerNetworkSvg,
    productionSvg,
    shippingSvg
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      routesListNew: null,
      isDraweOpen: false,
      userDetail: {},
      mobileView: false,
      toutInfo: null,
      drawerOpen: false,
      extraSmall: false,
      drawerIsOpen: false,
      showMenuContinue: true
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  watch: {
    // routesList: {
    //   handler () {
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.$root.$on('openDialog', (data) => {
      console.log('click click', data)
      if (data) {
        if (this.isDraweOpen) {
          this.hoverOut()
        } else {
          this.hoverIs()
        }
      } else {
        this.hoverOut()
      }
    })
    console.log('watchjhhhhhhhhhhhhhhhhhhhhhh', this.$route.meta.loadPage, this.routesList)
    this.routesListNew = []
    for (let index = 0; index < this.routesList.children.length; index++) {
      if (this.routesList.children[index].name !== 'Dashboard') {
        if (this.routesList.children[index].listing.length > 0) {
          this.routesListNew.push(this.routesList.children[index])
        }
      } else {
        this.routesListNew.push(this.routesList.children[index])
      }
    }
    console.log('this.routesListNew 1', this.routesListNew, this.$route.meta.loadPage, this.routesList)
    if (this.routesListNew !== null) {
      this.routesListNew.forEach(element => {
        if (element.name === this.$route.meta.loadPage) {
          element.meta.selected = true
        }
      });
    }
    this.setNavigation(this.$route.meta.loadPage)
    setTimeout(() => {
    }, 300)
    this.resizeWindowHandler();
    if (getCookies("lockDrawer") === "draweLocked" && !this.mobileView) {
      this.drawerIsOpen = true;
    } else {
      this.drawerIsOpen = false;
    }
    router.afterEach(() => {
      setTimeout(() => {
        this.setNavigation(this.$route.meta.loadPage)
      }, 100);
    })
    let userData = localStorage.getItem("employeeData1");
    this.userDetail = JSON.parse(userData);
  },
  methods: {
    hoverIs () {
      this.isDraweOpen = true
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "250px";
        document.getElementById("mySidenav").style.maxWidth = "250px";
        document.getElementById("main_content_box").style.paddingLeft = "266px";
        document.getElementById("mySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "250px";
        document.getElementById("sv_smySidenav").style.maxWidth = "250px";
        document.getElementById("sv_smySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      }
    },
    hoverOut () {
      this.isDraweOpen = false
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "70px";
        document.getElementById("mySidenav").style.maxWidth = "70px";
        document.getElementById("main_content_box").style.paddingLeft = "82px";
        document.getElementById("mySidenav").style.boxShadow = null;
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "0px";
        document.getElementById("sv_smySidenav").style.maxWidth = "0px";
        document.getElementById("sv_smySidenav").style.boxShadow = null;
      }
    },
    LogOut () {
      removeCookies('authToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    setNavigation (value) {
      console.log('this.routesList[index].meta.loadPage', this.$route.meta.loadPage, this.routesList)
      for (let index = 0; index < this.routesList.length; index++) {
        console.log('this.routesList[index].meta.loadPage', this.routesList[index].meta.loadPage)
        if (value === this.routesList[index].meta.loadPage) {
          this.routesListNew[index].meta.selected = true;
          console.log('this.routesList[index].meta.selected', this.routesList[index].meta)
          console.log('this.routesListNew', this.routesListNew)
          // this.routesList[index].meta.routIconSize.color = colors.white;
        } else {
          console.log('this.routesListNew', this.routesListNew)
          this.routesListNew[index].meta.selected = false;
          // this.routesList[index].meta.routIconSize.color = colors.white;
        }
      }
    },
    routeTo(path) {
      console.log('path', path)
      console.log('check11', this.$route)
      console.log('$routesList', this.routesList)
      this.$router.push({ name: path })
      for (let index = 0; index < this.routesList.length; index++) {
        if (this.$route.meta.loadPage === this.routesList[index].meta.loadPage) {
          this.routesListNew[index].meta.selected = true;
          // this.routesList[index].meta.routIconSize.color = colors.white;
        } else {
          this.routesListNew[index].meta.selected = false;
          // this.routesList[index].meta.routIconSize.color = colors.white;
        }
      }
    },
    closeDrawer() {
      this.showMenuContinue = false
        // console.log('check 11', getCookies("lockDrawer"))
      document.getElementById("overlay").style.display = "none";
      if (getCookies("lockDrawer") === "draweUnLocked" || getCookies("lockDrawer") === null) {
        document.getElementById("myTopnav").style.left = "70px";
        document.getElementById("mySidenav").style.minWidth = "70px";
        this.drawerIsOpen = false;
      }
      setTimeout(() => {
        this.showMenuContinue = true
      }, 600);
    },
    openDrawer() {
      // console.log('check 10', this.showMenuContinue)
      setTimeout(() => {
        if (this.showMenuContinue) {
          document.getElementById("mySidenav").style.minWidth = "260px";
          document.getElementById("menu_items_box").style.left = "275px";
          if (getCookies("lockDrawer") === "draweUnLocked") {
            // document.getElementById('myTopnav').style.left = '248px'
            this.showDashboadMenus = false;
            this.showHistory = false;
            this.showAddFavorite = false;
            this.showAvtarMenu = false;
            this.$root.$emit('closeCardPopUp', true)
          }
          this.drawerIsOpen = true;
        }
      }, 400);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  },
  beforeDestroy () {
    this.$root.$off("openDialog");
  }
};
</script>
<style scoped>
.textOverflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
