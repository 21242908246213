import axios from 'axios'
import {removeCookies} from '@/utils/cookies'
import * as config from '../config.js'
// import moment from 'moment'
/**
 * init class API
 * @param opt
 * @returns {AppApi}
 * @constructor
 */

// var execution;

// function incrementSeconds() {
//   var i = 0;
//   clearInterval(execution);

//   execution = setInterval(function() {
//     active()
//   }, 1000);

//   function active() {
//     i++;
//     // console.log('Counter Time For Logout, For testing purpose logout user if API doesn`t call for 20 seconds', i)
//     if (i > 300000){
//       localStorage.clear()
//       removeCookies('authToken')
//       window.location.href = ''
//     }
//   }
// }
function AppApi (opt) {
  if (!(this instanceof AppApi)) {
    return new AppApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
}

/**
 * Request to the server,
 * You fixed: https://gist.github.com/pranavrajs/66bccee3f8ba100742a1273db6f587af
 * @param url
 * @param callback
 * @returns {axios.Promise}
 * @private
 */

AppApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}

AppApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  // incrementSeconds ()
  var headers = {
    'Authorization': 'Bearer eb7ca14c72364a3c696f0fc95e256a08S1UHQsDk3YEc1TK/iMVaitNoRbTLbvDUqAGEnXA4ezoJtgw=',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear()
        removeCookies('authToken')
        window.location.href = ''
      }
    }
    return {
      success: 0,
      message: error.message
    }
  })
}

AppApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

AppApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AppApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    'Authorization': 'Bearer 23432567654',
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

// add document
// delete from tempt storage

// *******************TIMESHEET API START HERE*****************************/


// TIMESHEET UPDATE
AppApi.prototype.loginToSpecificCompany = async function (data, callback) {
  const requestUrl = 'api/v1/auth/webLogin'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.varifiedOTP = async function (data, callback) {
  const requestUrl = 'api/v1/auth/verifyLoginOTP'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.resendOtp = async function (data, callback) {
  const requestUrl = 'api/v1/auth/resendOTP'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetAmazonSageListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/cogsRequiredData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetAmazonSageReqItemListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/cogsItemList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetSageItemCodeListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/getSageItemCodeList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetSKUSearchListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/getSKUsList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetASINSearchListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/getASINList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetNewAmazonSageReportListSKU = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/sageReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetNewAmazonSageReportListSageItemCode = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/sageItemReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetSpecificDetailOfAmazonCOGS = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/sageRawData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetOrderIdSearchValue = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/getOrderIds'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetNewAmazonSageReportListAsin = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/sageASINReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetAmazonSAGElistingCost = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/cogsReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.getAccountingRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/accountingReqData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.getCityRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/reqCityList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.getStateRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/reqStateList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.getZipRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/reqZipList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.getPaymentRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/reqPaymentMethodList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetExtremeDogFencelistingAcc = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/accountingReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.AccGetExtremeDogFenceReconciliation = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edf/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetFBAInventoriesList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/getFBAInventories'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.ZeroSalesSageItemReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/zeroSales/sageItemReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.ZeroSalesSKUReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/zeroSales/sageReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.ZeroSalesASINReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/zeroSales/sageASINReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.ZeroSalesRawDataReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/zeroSales/sageRawData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.ASINSoldCountReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/asinSoldCount'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
//ACCOUNTING SECTION

//----------------->perimiter technology.com

AppApi.prototype.GetAccPeriTechnologyReqData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/ptReqData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.GetAccPeriTechnologyStateList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/consumer/shippingStateList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.GetAccPeriTechnologyGeographyChart = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/consumer/salesByGeography'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetAccPeriTechnologyListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/ordersAndRefunds'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AppApi.prototype.GetPerimeterReconciliationData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ExtremeDogDoor.com

AppApi.prototype.requiredDateData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/accountingReqData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.requiredExtDogCityList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/reqCityList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.requiredExtDogStateList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/reqStateList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.requiredExtDogZipList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/reqZipList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.requiredExtDogPaymentList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/reqPaymentMethodList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.overViewReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/accountingOverviewReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.GetExtremeDogDoorReconciliationData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->Walmart - Accounting Overview

AppApi.prototype.WalmartRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/walmart/accountingReqData'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WalmartReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/walmart/accountingOverviewReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WalmartReconciliation = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/walmart/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->Ebay - Accounting Overview

AppApi.prototype.EbayRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.FulfilmentLisitng = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/fulfillmentStatus'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.PaymentLisitng = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/paymentStatus'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.StateOrProvinceLisitng = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/stateOrProvince'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.PostalCodeLisitng = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/postalCode'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.EbayReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/accountingOverview'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.EbayReconciliationData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->Dealers.PetStop.com Sales

AppApi.prototype.DealersPetStopRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dps/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.DealersPetStopReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dps/sales'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ACCOUNTING - Shipworks

AppApi.prototype.ShipworkReqData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkSKUListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/itemSkuList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkItemsListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/itemNameList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkLisitngData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/salesReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ACCOUNTING - Shop.PetStop.com

AppApi.prototype.shopPetStopRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/sps/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.shopPetStopListingData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/sps/salesReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - Walmart - COGS by Channel (Walmart) (TEXT ONLY)

AppApi.prototype.COGSChannelTextOnlyReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/walmart/cogs/channel'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.COGSProductTextOnlyReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/walmart/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - Ebay - COGS by Channel (Ebay) (TEXT ONLY)

AppApi.prototype.EbayChannelTextOnlyReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/cogs/channel'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.EbayChannelReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/ebay/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - ShipWorks

AppApi.prototype.ShipwordOrderSourceListApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/cogs/orderSourceList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkOrderRequestedListApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/cogs/orderRequestedShippingList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkCogsByChannelListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/cogs/channel'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ShipworkCogsByProductListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/shipwork/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - AMAZON

AppApi.prototype.AmazonCogsByChannelListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/amazon/cogs/channel'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.AmazonCogsByProductListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/amazon/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - SAGE

AppApi.prototype.SageDateRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/sage/cogs/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.SageCogsListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/sage/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->COST OF GOODS SOLD - E-Commerce

AppApi.prototype.ECommCogsChannelListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/eCom/cogs/channel  '
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ECommCogsProductListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/eCom/cogs/product'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->CONSUMER SALES - FlexPetz

AppApi.prototype.FlexPetzGeogrphyStateList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/flexPet/consumer/shippingStateList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.FlexPetzGeogrphyList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/flexPet/consumer/salesByGeography'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ACCOUNTING - FlexPetz.com


AppApi.prototype.flexpetzAccountingDateRangeApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/flexPet/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.flexpetzAccountingReconciliation = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/flexPet/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.flexpetzAccountingReportListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/flexPet/accountingOverview'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->CONSUMERSALES - Perimeter Technologies

AppApi.prototype.PerimTexhnoSalesConsumerSales = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/consumer/sales'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.PerimTexhnoSalesProductPieChart = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/perimeterTech/consumer/salesByProductCategory'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
//----------------->CONSUMERSALES - OverView

AppApi.prototype.overViewReportListingRevCogsConsumerSales = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dpp/consumerSales/overview/cogsOverview'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.overViewReportListingBrandRevenue = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/overview/getBrandRevenue'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
//----------------->CONSUMERSALES - Extreme Dog Door

AppApi.prototype.ExtremeDogDoorConsumerSales = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/consumer/sales'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ExtremeDogDoorSalesByProductPieChart = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/consumer/salesByProductCategory'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ExtremeDogDoorStateList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/consumer/shippingStateList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.ExtremeDogDoorGeographyList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/edd/consumer/salesByGeography'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
//----------------->Warranty Analysis - Dealers.PetStop.com - Warranty Analysis

AppApi.prototype.WarrAnyDateRangeRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/dealerDateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyGetDealerListApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/getDealers'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyGetStatusListApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/getStatus'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnySkuDealeReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/skuBydealers'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnySkuYearDealerReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/skuYearsByDealers'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnySkuYearsReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/skuYears'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyUnreceivedRma = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/totalCostPerDealer'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyUnreceivedRmaList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/unreceivedRMA'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyUnreceivedRmaDateRange = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/rmaDateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.WarrAnyUnreceivedRmaDealerList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/dealerNetwork/getRMADealers'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ACCOUNTING - Amazon 

AppApi.prototype.AccountingAmazonReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/accounting/report'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.AccountingAmazonReconciliationReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/amazon/accounting/reconciliationInfo'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ADMIN PANEL

AppApi.prototype.getStaffList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/list'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.getStaffDetail = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/detail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.profileUpdate = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/imageUpload'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.getMenuOptionsList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/user/menuOptions'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.addNewUser = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/save'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.specificUserModuleList = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/staff/user/meOptions'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.UnblockUser = async function (data, callback) {
  const requestUrl = 'api/v1/auth/unblockUser'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
//----------------->PRODUCTION-----Stocks

AppApi.prototype.getStocksRequiredData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/dateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.getProductionItemListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/items'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.getProductionWarehouseListing = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/warehouse'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.getProductionWarehouseReport = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/stock'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

//----------------->ACCOUNTING-----SAGE

AppApi.prototype.AccountingSageDateRangeApi = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/sageSaleDateRange'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AppApi.prototype.AccountingSageReportData = async function (data, callback) {
  const requestUrl = 'api/v1/analytics/production/sageSaleReport'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
export default AppApi
