import Vue from 'vue'
import App from './App.vue'
// import './assets/tags'
import 'vue2-datepicker/index.css';
import './assets/tailwind.css'
import './assets/globals.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill-mention/dist/quill.mention.min.css'
import router from './router'
import store from './store'
import './filter/filter.js'
import './permission.js'
import vClickOutside from 'v-click-outside'
import VueApexCharts from 'vue-apexcharts'
Vue.config.productionTip = false
import VueRx from "vue-rx"; Vue.use(VueRx);
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
// import Vidle from 'v-idle'
// Vue.use(Vidle)
Vue.use(setupCalendar, {})
// Use the components
Vue.component('VCalendar', Calendar)
Vue.component('VDatePicker', DatePicker)
// TODAY 16-05 spredsheet
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import grid from 'vue-xgrid-lib'
import 'vue-xgrid-lib/dist/xgrid.css'

Vue.use(VueEasytable);
Vue.component('x-grid',grid.XGrid);


Vue.use(VueApexCharts)
Vue.use(VuejsClipper)
Vue.use(vClickOutside)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')