import Layout from '@/View/Admin/Layout/layout.vue'
import colors from '@/assets/colors.json'
export const Routing = { // dashboard object start
    path: '/',
    component: Layout,
    hidden: false,
    meta: {
        title: 'dashboards',
        icon: 'fas fa-border-all',
    },
    children: [
        {
            path: '/',
            component: () =>
                import ('@/View/Admin/dashboard/dashboard.vue'),
            name: 'Dashboard',
            listing: [],
            hidden: true,
            meta: {
                icon: 'fas fa-border-all',
                title: 'Dashboard',
                loadPage: 'Dashboard',
                permissionList: ['abc', 'def'],
                routeIcon: 'dashboardSvg',
                selected: false,
                routIconSize: { height: '30px', width: '30px', color: colors.text2 }
            },
            children: []
        },
        {
            path: '/consumer',
            component: () => import('@/View/Admin/consumerSales/Main/index.vue'),
            redirect: '/consumer/dashboard',
            name: 'Consumer',
            listing: [],
            hidden: false,
            meta: {
                routeIcon: 'consumerSalesSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
                icon: 'fas fa-border-all',
                title: 'Consumer Sales',
                loadPage: 'Consumer',
                selected: false,
                permissionList: ['abc', 'def'],
            },
            children: [
              {
                path: '/consumer/dashboard',
                component: () => import('@/View/Admin/consumerSales/conDashboard.vue'),
                name: 'conDashboard',
                hidden: true,
                meta: {
                  title: 'conDashboard',
                  loadPage: 'Consumer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/consumer/overview',
                component: () => import('@/View/Admin/consumerSales/overview/main.vue'),
                name: 'Overview',
                hidden: false,
                meta: {
                  title: 'Overview',
                  loadPage: 'Consumer',
                  color: '#ff5a5a',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/consumer/perimetertechnologies',
                component: () => import('@/View/Admin/consumerSales/perTechnologies/main.vue'),
                name: 'Perimeter Technologies',
                hidden: false,
                meta: {
                  title: 'Perimeter Technologies',
                  loadPage: 'Consumer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/consumer/extremedogfence',
                component: () => import('@/View/Admin/consumerSales/extDogFence.vue'),
                name: 'Extreme Dog Fence',
                hidden: false,
                meta: {
                  title: 'Extreme Dog Fence',
                  loadPage: 'Consumer',
                  color: '#ff5a5a',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/consumer/extremedogdoor',
                component: () => import('@/View/Admin/consumerSales/extDogDoor/main.vue'),
                name: 'Extreme Dog Door',
                hidden: false,
                meta: {
                  title: 'Extreme Dog Door',
                  loadPage: 'Consumer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/consumer/flexpetz',
                component: () => import('@/View/Admin/consumerSales/flexPetz/main.vue'),
                name: 'Flex Petz',
                hidden: false,
                meta: {
                  title: 'Flex Petz',
                  loadPage: 'Consumer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
            ]
        },
        {
            path: '/dealernetwork',
            redirect: '/dealernetwork/dashboard',
            component: () => import('@/View/Admin/dealer/Main/index.vue'),
            name: 'Dealer',
            listing: [],
            hidden: false,
            meta: {
                routeIcon: 'dealerNetworkSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
                icon: 'fas fa-border-all',
                title: 'Dealer Network',
                loadPage: 'Dealer',
                selected: false,
                permissionList: ['abc', 'def'],
            },
            children: [
              {
                path: '/dealernetwork/dashboard',
                component: () => import('@/View/Admin/dealer/dealDashboard.vue'),
                name: 'dealDashboard',
                hidden: true,
                meta: {
                  title: 'dealDashboard',
                  loadPage: 'Dealer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/dealernetwork/sales',
                component: () => import('@/View/Admin/dealer/sales.vue'),
                name: 'Sales',
                hidden: false,
                meta: {
                  title: 'Sales',
                  loadPage: 'Dealer',
                  selected: false,
                  color: '#2D2D2D',
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/dealernetwork/transmitters',
                component: () => import('@/View/Admin/dealer/transmitters.vue'),
                name: 'Transmitters',
                hidden: false,
                meta: {
                  title: 'Transmitters',
                  loadPage: 'Dealer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/dealernetwork/receivers',
                component: () => import('@/View/Admin/dealer/receivers.vue'),
                name: 'Receivers',
                hidden: false,
                meta: {
                  title: 'Receivers',
                  loadPage: 'Dealer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/dealernetwork/leadflow',
                component: () => import('@/View/Admin/dealer/leadFlow.vue'),
                name: 'Lead Flow',
                hidden: false,
                meta: {
                  title: 'Lead Flow',
                  loadPage: 'Dealer',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/dealernetwork/warranty',
                component: () => import('@/View/Admin/dealer/warrantyAnalysis/warranty.vue'),
                name: 'Warranty Analysis',
                hidden: false,
                meta: {
                  title: 'Warranty Analysis',
                  loadPage: 'Dealer',
                  selected: false,
                  color: '#2D2D2D',
                  permissionList: ['abc', 'def']
                }
              },
            ]
        },
        {
            path: '/costofgoods',
            component: () => import('@/View/Admin/costOfGood/Main/index.vue'),
            redirect: '/costofgoods/dashboard',
            name: 'Costofgood',
            listing: [],
            hidden: false,
            meta: {
                routeIcon: 'cogsSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
                icon: 'fas fa-border-all',
                title: 'Cost of Goods Sold',
                loadPage: 'Costofgood',
                selected: false,
                permissionList: ['abc', 'def'],
            },
            children: [
              {
                path: '/costofgoods/dashboard',
                component: () => import('@/View/Admin/costOfGood/cogsDash.vue'),
                name: 'Dashboard',
                hidden: true,
                meta: {
                  title: 'Dashboard',
                  loadPage: 'Costofgood',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/shipworks',
                component: () => import('@/View/Admin/costOfGood/shipworks/shipworks.vue'),
                name: 'Shipworks',
                hidden: false,
                meta: {
                  title: 'Shipworks',
                  loadPage: 'Costofgood',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/sage',
                component: () => import('@/View/Admin/costOfGood/SAGE/sage.vue'),
                name: 'SAGE',
                hidden: false,
                meta: {
                  title: 'SAGE',
                  loadPage: 'Costofgood',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/amazon',
                component: () => import('@/View/Admin/costOfGood/Amazon/amazon.vue'),
                name: 'Amazon',
                hidden: false,
                meta: {
                  title: 'Amazon',
                  color: '#2D2D2D',
                  loadPage: 'Costofgood',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/e-commerce',
                component: () => import('@/View/Admin/costOfGood/E-Commerce/eComm.vue'),
                name: 'E-Commerce',
                hidden: false,
                meta: {
                  title: 'E-Commerce',
                  color: '#2D2D2D',
                  loadPage: 'Costofgood',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/ebay',
                component: () => import('@/View/Admin/costOfGood/eBay/eBay.vue'),
                name: 'eBay',
                hidden: false,
                meta: {
                  title: 'eBay',
                  loadPage: 'Costofgood',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/walmart',
                component: () => import('@/View/Admin/costOfGood/walmart/walmart.vue'),
                name: 'Walmart',
                hidden: false,
                meta: {
                  title: 'Walmart',
                  loadPage: 'Costofgood',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/amazonsage',
                component: () => import('@/View/Admin/costOfGood/AmazonSage/amazoSage.vue'),
                name: 'Amazon Sage',
                hidden: false,
                meta: {
                  title: 'Amazon Sage',
                  color: '#2D2D2D',
                  loadPage: 'Costofgood',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/costofgoods/amazonsage/asin',
                component: () => import('@/View/Admin/costOfGood/AmazonSage/ASINSales/index.vue'),
                name: 'ASIN Sales',
                hidden: true,
                meta: {
                  title: 'ASIN Sales',
                  color: '#2D2D2D',
                  loadPage: 'Costofgood',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
            ]
        },
        {
            path: '/accounting',
            component: () => import('@/View/Admin/accounting/Main/index.vue'),
            redirect: '/accounting/dashboard',
            name: 'Accounting',
            listing: [],
            hidden: false,
            meta: {
                routeIcon: 'accountingSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
                icon: 'fas fa-border-all',
                title: 'Accounting',
                loadPage: 'Accounting',
                selected: false,
                permissionList: ['abc', 'def'],
            },
            children: [
              {
                path: '/accounting/dashboard',
                component: () => import('@/View/Admin/accounting/accoDashboard.vue'),
                name: 'accoDashboard',
                hidden: true,
                meta: {
                  title: 'accoDashboard',
                  loadPage: 'Accounting',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/perimetertechnologies',
                component: () => import('@/View/Admin/accounting/perimeterTechnology/perimTechno.vue'),
                name: 'PerimeterTechnologies.com',
                hidden: false,
                meta: {
                  title: 'PerimeterTechnologies.com',
                  loadPage: 'Accounting',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/extremedogdoor',
                component: () => import('@/View/Admin/accounting/extremeDogDoor/extremeDogDoor.vue'),
                name: 'ExtremeDogDoor.com',
                hidden: false,
                meta: {
                  title: 'ExtremeDogDoor.com',
                  loadPage: 'Accounting',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/extremedogfence',
                component: () => import('@/View/Admin/accounting/extremeDogFence/extDogFence.vue'),
                name: 'ExtremeDogFence.com',
                hidden: false,
                meta: {
                  title: 'ExtremeDogFence.com',
                  loadPage: 'Accounting',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/shipworks',
                component: () => import('@/View/Admin/accounting/shipWorks.vue'),
                name: 'Shipworks',
                hidden: false,
                meta: {
                  title: 'Shipworks',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/sage',
                component: () => import('@/View/Admin/accounting/sage.vue'),
                name: 'SAGEAcc',
                hidden: false,
                meta: {
                  title: 'SAGE',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/amazon',
                component: () => import('@/View/Admin/accounting/amazon/amazon.vue'),
                name: 'AmazonAcc',
                hidden: false,
                meta: {
                  title: 'Amazon',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/walmart',
                component: () => import('@/View/Admin/accounting/walmart/walmart.vue'),
                name: 'WalmartAcc',
                hidden: false,
                meta: {
                  title: 'Walmart',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/ebay',
                component: () => import('@/View/Admin/accounting/eBay/ebay.vue'),
                name: 'Ebay',
                hidden: false,
                meta: {
                  title: 'Ebay',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/dealerspetstop',
                component: () => import('@/View/Admin/accounting/dealersPetStop.vue'),
                name: 'DealersPetStop.com',
                hidden: false,
                meta: {
                  title: 'DealersPetStop.com',
                  color: '#2D2D2D',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/flexpetz',
                component: () => import('@/View/Admin/accounting/flexPetz/flexPetz.vue'),
                name: 'FlexPetz.com',
                hidden: false,
                meta: {
                  color: '#2D2D2D',
                  title: 'FlexPetz.com',
                  loadPage: 'Accounting',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/accounting/shoppetstop',
                component: () => import('@/View/Admin/accounting/shopPetStop.vue'),
                name: 'Shop.PetStop.com',
                hidden: false,
                meta: {
                  title: 'Shop.PetStop.com',
                  loadPage: 'Accounting',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
            ]
        },
        {
            path: '/shipping',
            component: () => import('@/View/Admin/shipping/Main/index.vue'),
            redirect: '/shipping/dashboard',
            name: 'Shipping',
            listing: [],
            hidden: false,
            meta: {
                routeIcon: 'shippingSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
                icon: 'fas fa-border-all',
                title: 'Shipping',
                loadPage: 'Shipping',
                selected: false,
                permissionList: ['abc', 'def'],
            },
            children: [
              {
                path: '/shipping/dashboard',
                component: () => import('@/View/Admin/shipping/shipDashboard.vue'),
                name: 'shipDashboard',
                hidden: true,
                meta: {
                  title: 'shipDashboard',
                  loadPage: 'Shipping',
                  color: '#2D2D2D',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
              {
                path: '/shipping/amazonshipworks',
                component: () => import('@/View/Admin/shipping/amazonShipwork.vue'),
                name: 'Amazon Shipworks',
                hidden: false,
                meta: {
                  title: 'Amazon Shipworks',
                  color: '#ff5a5a',
                  loadPage: 'Shipping',
                  selected: false,
                  permissionList: ['abc', 'def']
                }
              },
            ]
        },
        {
          path: '/production',
          component: () => import('@/View/Admin/production/Main/index.vue'),
          redirect: '/production/dashboard',
          name: 'Production',
          listing: [],
          hidden: false,
          meta: {
              routeIcon: 'productionSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 },
              icon: 'fas fa-border-all',
              title: 'Production',
              loadPage: 'Production',
              selected: false,
              permissionList: ['abc', 'def'],
          },
          children: [
            {
              path: '/production/dashboard',
              component: () => import('@/View/Admin/production/proDashboard.vue'),
              name: 'proDashboard',
              hidden: true,
              meta: {
                title: 'proDashboard',
                loadPage: 'Production',
                color: '#2D2D2D',
                selected: false,
                permissionList: ['abc', 'def']
              }
            },
            {
              path: '/production/stocks',
              component: () => import('@/View/Admin/production/stock.vue'),
              name: 'Stock',
              hidden: false,
              meta: {
                title: 'Stock',
                color: '#2D2D2D',
                loadPage: 'Production',
                selected: false,
                permissionList: ['abc', 'def']
              }
            },
          ]
        },
    ]
}