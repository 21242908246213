<template>
  <svg version="1.1" viewBox="0 0 2048 2048" :width="width" :fill="color" :height="height" xmlns="http://www.w3.org/2000/svg">
    <path transform="translate(1234)" d="m0 0h693l3 2 17 5 15 6 15 8 14 10 10 9 7 6 9 11 10 14 9 17 9 27 2 4h1v954l-2 1-4 15-7 19-8 15-8 12-7 9h-2l-2 4-9 9-14 11-13 8-19 9-20 6-17 3-12 1h-653l-19-2-17-4-20-8-17-9-12-9-10-9-14-14-11-16-9-16-7-19-5-21-1-9-1-893 1-33 5-25 7-19 8-16 8-12 11-13 4-5h2v-2l11-9 12-9 16-9 17-7 16-5z"/>
    <path transform="translate(134,853)" d="m0 0h669l20 3 14 4 17 7 18 10 13 10 10 9 7 7 10 13 8 13 8 16 7 21 3 16 1 9v918l-3 22-6 20-7 16-9 15-9 12-12 13-10 9-18 12-16 8-19 7-19 5h-683l-29-8-16-7-16-9-13-10-12-11-11-12-7-10-10-17-7-17-7-25v-944l7-24 8-19 8-14 9-12 9-10 1-2h2v-2l8-7 13-10 17-10 19-8 19-5z"/>
    <path transform="translate(125)" d="m0 0h690l36 12 17 9 16 12 10 9 10 10 13 18 10 19 7 20 4 19 1 10v406l-3 21-5 18-7 16-8 15-12 16-11 12h-2v2l-14 11-18 11-18 8-17 5-17 3-12 1h-655l-17-2-20-5-15-6-16-8-14-10-14-12-12-13-9-13-8-14-7-16-6-20-2-8v-432l2-5 6-21 8-18 10-17 9-11 9-10 11-10 16-11 10-6 25-10z"/>
    <path transform="translate(1243,1365)" d="m0 0h670l20 3 17 5 15 7 13 7 14 10 12 11 9 9 11 15 8 14 8 18 6 20h2v443h-2l-4 15-6 17-10 19-10 14-11 12-9 9-15 11-18 10-15 6-20 6-8 2h-683l-25-7-16-6-15-8-17-12-14-13-7-8-9-12-9-15-8-18-6-22-2-14v-414l4-22 6-18 9-19 13-19 11-12 9-9 15-11 17-10 15-6 12-4 16-3z"/>
    <path transform="translate(1231,2047)" d="m0 0 3 1z"/>
    <path transform="translate(122,2047)" d="m0 0 2 1z"/>
    <path transform="translate(817)" d="m0 0 2 1z"/>
    </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
