<template>
  <div>
      <!-- <LeftNavIcons v-if="mobileView" :routesList="routes" /> -->
      <div class="flex" v-if="showAll">
        <LeftNavIcons v-if="mobileView" :routesList="routes" />
        <div :class=" mobileView ? `main_container smv_main_container` : ``" class="bg-screen_bg">
          <div id="overlay"></div>
          <div v-if="!mobileView">
            <LeftNavIcons :routesList="routes" />
          </div>
          <TopNavbar :routesList="routes"/>
        </div>
      </div>
    <div id="main_content_box" class="min-h-screen main_content_box bg-white">
      <router-view />
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
      v-if="$store.getters.showAlert"
      v-bind:status="$store.getters.showAlert"
      v-bind:message="$store.getters.alertMessage"
      v-bind:color="$store.getters.alertColor"
    >
    </snakBar>
    <DownloadLoader/>
  </div>
</template>
<script>
import API from '@/api/App.js'
import DownloadLoader from '@/View/components/downloadLoader'
import {Routing} from '../router/index.js'
import LeftNavIcons from "@/View/Dashboard/components/leftNavbar.vue";
import TopNavbar from "@/View/Dashboard/components/topNavbar.vue";
// import {FilterPermissions} from '@/utils/Permissions.js'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
export default {
  name: "landing-page",
  components: {
    TopNavbar,
    DownloadLoader,
    LeftNavIcons,
    Loader,
    snakBar,
  },
  data() {
    return {
      showAll: false,
      userDetail: {},
      mobileView: false,
      extraSmall: false,
      routes: [],
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    console.log("FFFFFFFFFRRRRRRRRRROOOOOOOMMMMMMMMMMMMMMM 1111111111111111")
    this.$root.$emit('isHideButton', true)
    localStorage.removeItem('routingData')
    this.resizeWindowHandler();
    this.userDetail = JSON.parse(localStorage.getItem('employeeData2'))
    this.getNavigationIconFromRouterWithPermissions()
    if (this.userDetail === null) {
      this.$router.push({name: 'login'})
    }
    if (this.$route.path === '/dashboard' || this.$route.path === '/') {
      console.log('userDetail', this.$route.path)
      this.$router.push({name: 'Dashboard'})
    }
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    this.$root.$off("drawercheckLOcked");
    this.$root.$off("favoriteTrue");
  },
  methods: {
    getNavigationIconFromRouterWithPermissions () {
      if (this.userDetail.isAdmin === true) {
        let temp = Routing
        console.log('temp', temp)
        for (let index = 0; index < temp.children.length; index++) {
          console.log(' temp[index]',  temp.children[index])
          temp.children[index].hidden = true
          if (temp.children[index].children.length > 0) {
            temp.children[index].children.forEach(b => {
            console.log('b.meta', b.meta)
              if (b.meta !== undefined) {
                console.log('bbbbbbbbbbbbbb', b)
                if (!b.hidden) {
                  let tempDataListing = []
                  tempDataListing = b
                  let indexB = temp.children[index].listing.findIndex(x => x.name === b.name)
                  if (indexB === -1) {
                    temp.children[index].listing.push(tempDataListing)
                  }
                }
              }
            })
          }
        }
        console.log('temptemptemptemptemp', temp)
        this.routes = temp
        localStorage.setItem('routingData', JSON.stringify(this.routes))
        this.showAll = true
      } else {
        this.routes = []
        this.getModuleData()
      }
    },
    getModuleData () {
      API.specificUserModuleList(
        this.userDetail.userId,
          response => {
            let nonAdminRouting = response.Data
            let mainRouting = Routing
            console.log('main routing-------->', mainRouting)
            console.log('Second routing-------->', nonAdminRouting)
            if (nonAdminRouting !== null) {
              for (let IM = 0; IM < mainRouting.children.length; IM++) {
                for (let IS = 0; IS < nonAdminRouting.length; IS++) {
                  if (mainRouting.children[IM].meta.title === nonAdminRouting[IS].mainMenuName) {
                    mainRouting.children[IM].hidden = true
                  }
                  if (mainRouting.children[IM].children.length > 0 && nonAdminRouting[IS].subMenuList.length > 0) {
                    for (let JM = 0; JM < mainRouting.children[IM].children.length; JM++) {
                      for (let JS = 0; JS < nonAdminRouting[IS].subMenuList.length; JS++) {
                        if ((mainRouting.children[IM].children[JM].meta.title === nonAdminRouting[IS].subMenuList[JS].subMenuName) && (mainRouting.children[IM].meta.title === nonAdminRouting[IS].mainMenuName)) {
                          mainRouting.children[IM].children[JM].hidden = true
                          let tempDataListing = []
                          tempDataListing = mainRouting.children[IM].children[JM]
                          let indexB = mainRouting.children[IM].listing.findIndex(x => x.name ===  mainRouting.children[IM].children[JM].name)
                          console.log('indexB', indexB)
                          if (indexB === -1) {
                            mainRouting.children[IM].listing.push(tempDataListing)
                          }
                        }
                      }
                    }
                  }
                }
              }
              console.log('mainRoutingmainRouting', mainRouting)
            } else {
              for (let IM = 0; IM < mainRouting.children.length; IM++) {
                if (mainRouting.children[IM].name === 'Dashboard') {
                  mainRouting.children[IM].hidden = true
                }
                if (mainRouting.children[IM].children.length > 0) {
                  mainRouting.children[IM].children.forEach(b => {
                    b.hidden = false
                      if (b.meta !== undefined) {
                        console.log('bbbbbbbbbbbbbb', b)
                        let tempDataListing = []
                        tempDataListing = b
                        let indexB = mainRouting.children[IM].listing.findIndex(x => x.name === b.name)
                        if (indexB === -1) {
                          mainRouting.children[IM].listing.push(tempDataListing)
                        }
                      }
                    })
                }
              }
            }
            this.routes = mainRouting
            localStorage.setItem('routingData', JSON.stringify(this.routes))
            this.showAll = true
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  }
};
</script>
<style scoped>

</style>