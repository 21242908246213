
import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function AppModal () {
  if (!(this instanceof AppModal)) {
    return new AppModal()
  }
}


AppModal.prototype.loginToSpecificCompany = async function (
  emailAddress,
  password,
  platform,
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('emailAddress', emailAddress)
  data.append('password', password)
  data.append('platform', platform)
  Api.loginToSpecificCompany(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.varifiedOTP = async function (
  loginToken,
  otp,
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('loginToken', loginToken)
  data.append('otp', otp)
  Api.varifiedOTP(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.resendOtp = async function (
  loginToken,
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('loginToken', loginToken)
  Api.resendOtp(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetAmazonSageListing = async function (
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.GetAmazonSageListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetAmazonSAGElistingCost = async function (
  fromDate,
  toDate,
  itemList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('itemList', itemList.length > 0 ? JSON.stringify(itemList) : '')
  data.append('export', exportTo)
  Api.GetAmazonSAGElistingCost(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetNewAmazonSageReportListSKU = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  isZeroSales,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  // data.append('productIds', productIds.length > 0 ? JSON.stringify(productIds) : '')
  data.append('productIds', productIds)
  data.append('export', exportTo)
  data.append('isZeroSales', isZeroSales)
  Api.GetNewAmazonSageReportListSKU(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetNewAmazonSageReportListSageItemCode = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  isZeroSales,
  callBackFunc,
  onError
) {
  const data = new FormData()
  console.log('fromDatefromDatefromDatefromDate', fromDate)
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  // data.append('productIds', productIds.length > 0 ? JSON.stringify(productIds) : '')
  data.append('productIds', productIds)
  data.append('export', exportTo)
  data.append('isZeroSales', isZeroSales)
  Api.GetNewAmazonSageReportListSageItemCode(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetSpecificDetailOfAmazonCOGS = async function (
  fromDate,
  toDate,
  sku,
  sageItemCode,
  asin,
  orderIds,
  start,
  length,
  exportTo,
  isZeroSales,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('sku', sku)
  data.append('sageItemCode', sageItemCode)
  data.append('asin', asin)
  data.append('orderIds', orderIds)
  data.append('start', start)
  data.append('length', length)
  data.append('export', exportTo)
  data.append('isZeroSales', isZeroSales)
  Api.GetSpecificDetailOfAmazonCOGS(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetNewAmazonSageReportListAsin = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  isZeroSales,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  // data.append('productIds', productIds.length > 0 ? JSON.stringify(productIds) : '')
  data.append('productIds', productIds)
  data.append('export', exportTo)
  data.append('isZeroSales', isZeroSales)
  Api.GetNewAmazonSageReportListAsin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getAccountingRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.getAccountingRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetOrderIdSearchValue = async function (
  searchValue,
  start,
  length,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchVal', searchValue)
  data.append('start', start)
  data.append('length', length)
  Api.GetOrderIdSearchValue(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.getCityRequiredData = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getCityRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getStateRequiredData = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getStateRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getZipRequiredData = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getZipRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getPaymentRequiredData = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getPaymentRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetExtremeDogFencelistingAcc = async function (
  from,
  to,
  cityList,
  stateList,
  zipList,
  paymentMethodList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('cityList', cityList.length > 0 ? JSON.stringify(cityList) : '')
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  data.append('zipList', zipList.length > 0 ? JSON.stringify(zipList) : '')
  data.append('paymentMethodList', paymentMethodList.length > 0 ? JSON.stringify(paymentMethodList) : '')
  data.append('export', exportTo)
  Api.GetExtremeDogFencelistingAcc(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.AccGetExtremeDogFenceReconciliation = async function (
  from,
  to,
  cityList,
  stateList,
  zipList,
  paymentMethodList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('cityList', cityList.length > 0 ? JSON.stringify(cityList) : '')
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  data.append('zipList', zipList.length > 0 ? JSON.stringify(zipList) : '')
  data.append('paymentMethodList', paymentMethodList.length > 0 ? JSON.stringify(paymentMethodList) : '')
  data.append('export', exportTo)
  Api.AccGetExtremeDogFenceReconciliation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetFBAInventoriesList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchValue', searchValue)
  data.append('sortColumnName', sortColumnName)
  data.append('sortColumnOrder', sortColumnOrder)
  data.append('export', exportTo)
  Api.GetFBAInventoriesList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.ZeroSalesSageItemReport = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('productIds', productIds)
  data.append('export', exportTo)
  Api.ZeroSalesSageItemReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.ZeroSalesSKUReport = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('productIds', productIds)
  data.append('export', exportTo)
  Api.ZeroSalesSKUReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.ZeroSalesASINReport = async function (
  fromDate,
  toDate,
  productIds,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('productIds', productIds)
  data.append('export', exportTo)
  Api.ZeroSalesASINReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.ZeroSalesRawDataReport = async function (
  fromDate,
  toDate,
  sku,
  sageItemCode,
  asin,
  orderIds,
  start,
  length,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  data.append('sku', sku)
  data.append('sageItemCode', sageItemCode)
  data.append('asin', asin)
  data.append('orderIds', orderIds)
  data.append('start', start)
  data.append('length', length)
  data.append('export', exportTo)
  Api.ZeroSalesRawDataReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.ASINSoldCountReport = async function (
  productIds,
  dateReq,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('productIds', productIds)
  data.append('dateReq', JSON.stringify(dateReq))
  data.append('export', exportTo)
  Api.ASINSoldCountReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetAmazonSageReqItemListing = async function (
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchVal', searchVal)
  Api.GetAmazonSageReqItemListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetSageItemCodeListing = async function (
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchVal', searchVal)
  Api.GetSageItemCodeListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetSKUSearchListing = async function (
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchVal', searchVal)
  Api.GetSKUSearchListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetASINSearchListing = async function (
  searchVal,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchVal', searchVal)
  Api.GetASINSearchListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//ACCOUNTING SECTION HERE----------------------------

//----------------->perimiter technology.com
AppModal.prototype.GetAccPeriTechnologyReqData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.GetAccPeriTechnologyReqData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.GetAccPeriTechnologyStateList = async function (
  searchValue, 
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.GetAccPeriTechnologyStateList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetAccPeriTechnologyGeographyChart = async function (
  from,
  to,
  stateList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  Api.GetAccPeriTechnologyGeographyChart(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetAccPeriTechnologyListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.GetAccPeriTechnologyListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetPerimeterReconciliationData = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.GetPerimeterReconciliationData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ExtremeDogDoor.com
AppModal.prototype.requiredDateData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.requiredDateData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.requiredExtDogCityList = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.requiredExtDogCityList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.requiredExtDogStateList = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.requiredExtDogStateList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.requiredExtDogZipList = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.requiredExtDogZipList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.requiredExtDogPaymentList = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.requiredExtDogPaymentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.overViewReportListing = async function (
  from,
  to,
  cityList,
  stateList,
  zipList,
  paymentMethodList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('cityList', cityList.length > 0 ? JSON.stringify(cityList) : '')
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  data.append('zipList', zipList.length > 0 ? JSON.stringify(zipList) : '')
  data.append('paymentMethodList', paymentMethodList.length > 0 ? JSON.stringify(paymentMethodList) : '')
  data.append('export', exportTo)
  Api.overViewReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.GetExtremeDogDoorReconciliationData = async function (
  from,
  to,
  cityList,
  stateList,
  zipList,
  paymentMethodList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('cityList', cityList.length > 0 ? JSON.stringify(cityList) : '')
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  data.append('zipList', zipList.length > 0 ? JSON.stringify(zipList) : '')
  data.append('paymentMethodList', paymentMethodList.length > 0 ? JSON.stringify(paymentMethodList) : '')
  data.append('export', exportTo)
  Api.GetExtremeDogDoorReconciliationData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->Walmart - Accounting Overview
AppModal.prototype.WalmartRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.WalmartRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WalmartReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.WalmartReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WalmartReconciliation = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.WalmartReconciliation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


//----------------->Walmart - Accounting Overview

AppModal.prototype.EbayRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.EbayRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.FulfilmentLisitng = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.FulfilmentLisitng(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.PaymentLisitng = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.PaymentLisitng(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.StateOrProvinceLisitng = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.StateOrProvinceLisitng(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.PostalCodeLisitng = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.PostalCodeLisitng(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.EbayReportListing = async function (
  from,
  to,
  fulfillmentList,
  paymentList,
  stateOrProvinceList,
  postalCodeList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('fulfillmentList', fulfillmentList.length > 0 ? JSON.stringify(fulfillmentList) : '')
  data.append('paymentList', paymentList.length > 0 ? JSON.stringify(paymentList) : '')
  data.append('stateOrProvinceList', stateOrProvinceList.length > 0 ? JSON.stringify(stateOrProvinceList) : '')
  data.append('postalCodeList', postalCodeList.length > 0 ? JSON.stringify(postalCodeList) : '')
  data.append('export', exportTo)
  Api.EbayReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.EbayReconciliationData = async function (
  from,
  to,
  fulfillmentList,
  paymentList,
  stateOrProvinceList,
  postalCodeList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('fulfillmentList', fulfillmentList.length > 0 ? JSON.stringify(fulfillmentList) : '')
  data.append('paymentList', paymentList.length > 0 ? JSON.stringify(paymentList) : '')
  data.append('stateOrProvinceList', stateOrProvinceList.length > 0 ? JSON.stringify(stateOrProvinceList) : '')
  data.append('postalCodeList', postalCodeList.length > 0 ? JSON.stringify(postalCodeList) : '')
  data.append('export', exportTo)
  Api.EbayReconciliationData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
//----------------->Dealers.PetStop.com Sales
AppModal.prototype.DealersPetStopRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.DealersPetStopRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.DealersPetStopReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.DealersPetStopReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkReqData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.ShipworkReqData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkSKUListing = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.ShipworkSKUListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkItemsListing = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.ShipworkItemsListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkLisitngData = async function (
  from,
  to,
  itemSKUList,
  itemNameList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('itemSKUList', itemSKUList.length > 0 ? JSON.stringify(itemSKUList) : '')
  data.append('itemNameList', itemNameList.length > 0 ? JSON.stringify(itemNameList) : '')
  data.append('export', exportTo)
  Api.ShipworkLisitngData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ACCOUNTING - Shop.PetStop.com
AppModal.prototype.shopPetStopRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.shopPetStopRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.shopPetStopListingData = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.shopPetStopListingData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


//----------------->COST OF GOODS SOLD - Walmart - COGS by Channel (Walmart) (TEXT ONLY)

AppModal.prototype.COGSChannelTextOnlyReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.COGSChannelTextOnlyReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.COGSProductTextOnlyReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.COGSProductTextOnlyReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


//----------------->COST OF GOODS SOLD - Walmart - COGS by Channel (Walmart) (TEXT ONLY)

AppModal.prototype.EbayChannelTextOnlyReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.EbayChannelTextOnlyReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.EbayChannelReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.EbayChannelReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


//----------------->COST OF GOODS SOLD - SHIPWORK

AppModal.prototype.ShipwordOrderSourceListApi = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.ShipwordOrderSourceListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkOrderRequestedListApi = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.ShipworkOrderRequestedListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkCogsByChannelListing = async function (
  from,
  to,
  orderSourceList,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderSourceList', orderSourceList.length > 0 ? JSON.stringify(orderSourceList) : '')
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.ShipworkCogsByChannelListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ShipworkCogsByProductListing = async function (
  from,
  to,
  itemNameList,
  orderSourceList,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('itemNameList', itemNameList.length > 0 ? JSON.stringify(itemNameList) : '')
  data.append('orderSourceList', orderSourceList.length > 0 ? JSON.stringify(orderSourceList) : '')
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.ShipworkCogsByProductListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->COST OF GOODS SOLD - AMAZON

AppModal.prototype.AmazonCogsByChannelListing = async function (
  from,
  to,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.AmazonCogsByChannelListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.AmazonCogsByProductListing = async function (
  from,
  to,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.AmazonCogsByProductListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->COST OF GOODS SOLD - AMAZON

AppModal.prototype.SageDateRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.SageDateRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.SageCogsListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.SageCogsListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->COST OF GOODS SOLD - E-Commerce

AppModal.prototype.ECommCogsChannelListing = async function (
  from,
  to,
  orderSourceList,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderSourceList', orderSourceList.length > 0 ? JSON.stringify(orderSourceList) : '')
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.ECommCogsChannelListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ECommCogsProductListing = async function (
  from,
  to,
  orderSourceList,
  orderRequestedShippingList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderSourceList', orderSourceList.length > 0 ? JSON.stringify(orderSourceList) : '')
  data.append('orderRequestedShippingList', orderRequestedShippingList.length > 0 ? JSON.stringify(orderRequestedShippingList) : '')
  data.append('export', exportTo)
  Api.ECommCogsProductListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


//----------------->ACCOUNTING - FlexPetz.com

AppModal.prototype.FlexPetzGeogrphyStateList = async function (
  searchValue, 
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.FlexPetzGeogrphyStateList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.FlexPetzGeogrphyList = async function (
  from,
  to,
  stateList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  Api.FlexPetzGeogrphyList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ACCOUNTING - FlexPetz.com

AppModal.prototype.flexpetzAccountingDateRangeApi = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.flexpetzAccountingDateRangeApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.flexpetzAccountingReconciliation = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.flexpetzAccountingReconciliation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.flexpetzAccountingReportListing = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.flexpetzAccountingReportListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->CONSUMERSALES - Perimeter Technologies

AppModal.prototype.PerimTexhnoSalesConsumerSales = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.PerimTexhnoSalesConsumerSales(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.PerimTexhnoSalesProductPieChart = async function (
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  Api.PerimTexhnoSalesProductPieChart(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->CONSUMERSALES - OverView

AppModal.prototype.overViewReportListingRevCogsConsumerSales = async function (
  from,
  to,
  orderSourceList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('orderSourceList', orderSourceList.length > 0 ? JSON.stringify(orderSourceList) : '')
  Api.overViewReportListingRevCogsConsumerSales(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.overViewReportListingBrandRevenue = async function (
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  Api.overViewReportListingBrandRevenue(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->CONSUMERSALES - Extreme Dog Door

AppModal.prototype.ExtremeDogDoorConsumerSales = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.ExtremeDogDoorConsumerSales(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ExtremeDogDoorSalesByProductPieChart = async function (
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  Api.ExtremeDogDoorSalesByProductPieChart(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ExtremeDogDoorStateList = async function (
  searchValue, 
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.ExtremeDogDoorStateList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.ExtremeDogDoorGeographyList = async function (
  from,
  to,
  stateList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('stateList', stateList.length > 0 ? JSON.stringify(stateList) : '')
  Api.ExtremeDogDoorGeographyList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
//----------------->CONSUMERSALES - Extreme Dog Door

AppModal.prototype.WarrAnyDateRangeRequiredData = async function (
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('requestType', requestType)
  Api.WarrAnyDateRangeRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyGetDealerListApi = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.WarrAnyGetDealerListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyGetStatusListApi = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.WarrAnyGetStatusListApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnySkuDealeReport = async function (
  from,
  to,
  dealerNameList,
  statusNameList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('dealerNameList', dealerNameList.length > 0 ? JSON.stringify(dealerNameList) : '')
  data.append('statusNameList', statusNameList.length > 0 ? JSON.stringify(statusNameList) : '')
  data.append('export', exportTo)
  Api.WarrAnySkuDealeReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnySkuYearDealerReport = async function (
  from,
  to,
  dealerNameList,
  statusNameList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('dealerNameList', dealerNameList.length > 0 ? JSON.stringify(dealerNameList) : '')
  data.append('statusNameList', statusNameList.length > 0 ? JSON.stringify(statusNameList) : '')
  data.append('export', exportTo)
  Api.WarrAnySkuYearDealerReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnySkuYearsReport = async function (
  from,
  to,
  dealerNameList,
  statusNameList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('dealerNameList', dealerNameList.length > 0 ? JSON.stringify(dealerNameList) : '')
  data.append('statusNameList', statusNameList.length > 0 ? JSON.stringify(statusNameList) : '')
  data.append('export', exportTo)
  Api.WarrAnySkuYearsReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyUnreceivedRma = async function (
  from,
  to,
  received,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('received', received)
  Api.WarrAnyUnreceivedRma(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyUnreceivedRmaList = async function (
  from,
  to,
  dealerIdList,
  received,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('dealerIdList', dealerIdList.length > 0 ? JSON.stringify(dealerIdList) : '')
  data.append('received', received)
  data.append('export', exportTo)
  Api.WarrAnyUnreceivedRmaList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyUnreceivedRmaDateRange = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.WarrAnyUnreceivedRmaDateRange(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.WarrAnyUnreceivedRmaDealerList = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.WarrAnyUnreceivedRmaDealerList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ACCOUNTING - Amazon 

AppModal.prototype.AccountingAmazonReport = async function (
  from,
  to,
  searchValue,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('searchValue', searchValue)
  data.append('export', exportTo)
  Api.AccountingAmazonReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.AccountingAmazonReconciliationReport = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.AccountingAmazonReconciliationReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->PRODUCTION - Stocks 

AppModal.prototype.getStocksRequiredData = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.getStocksRequiredData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getProductionItemListing = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getProductionItemListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getProductionWarehouseListing = async function (
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('searchValue', searchValue)
  Api.getProductionWarehouseListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getProductionWarehouseReport = async function (
  from,
  to,
  itemCodeList,
  warehouseCodeList,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('itemCodeList', itemCodeList.length > 0 ? JSON.stringify(itemCodeList) : '')
  data.append('warehouseCodeList', warehouseCodeList.length > 0 ? JSON.stringify(warehouseCodeList) : '')
  data.append('export', exportTo)
  Api.getProductionWarehouseReport(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ADMIN PANEL

AppModal.prototype.getStaffList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchValue', searchValue)
  data.append('sortColumnName', sortColumnName)
  data.append('sortColumnOrder', sortColumnOrder)
  data.append('isActive', isActive)
  Api.getStaffList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


AppModal.prototype.getStaffDetail = async function (
  staffId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('staffId', staffId)
  Api.getStaffDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.profileUpdate = async function (
  staffId,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('staffId', staffId)
  data.append('file', file)
  Api.profileUpdate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.getMenuOptionsList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.getMenuOptionsList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.addNewUser = async function (
  staffData,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('staffData', JSON.stringify(staffData))
  data.append('file', file)
  Api.addNewUser(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.specificUserModuleList = async function (
  staffId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('staffId', staffId)
  Api.specificUserModuleList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AppModal.prototype.UnblockUser = async function (
  staffId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('staffId', staffId)
  Api.UnblockUser(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

//----------------->ACCOUNTING-----SAGE

AppModal.prototype.AccountingSageDateRangeApi = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.AccountingSageDateRangeApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AppModal.prototype.AccountingSageReportData = async function (
  from,
  to,
  exportTo,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('from', from)
  data.append('to', to)
  data.append('export', exportTo)
  Api.AccountingSageReportData(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
export default AppModal
